import React from 'react'
import App from './App'
import {BrowserRouter as Router}  from 'react-router-dom'

import { createRoot } from 'react-dom/client'
import { Provider} from 'react-redux'
import store from './store/index'
import { HeadProvider } from 'react-head'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    // <React.StrictMode>
    <HeadProvider>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </HeadProvider>
    // </React.StrictMode>
)