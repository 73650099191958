import { 
  urlCore, 
  urlPickit, 
  getUnknown, 
  setUnknown, 
  invoiceType, 
  sandbox,
  pais } from './../config'
import random from 'random-string-generator'
import { newSchedule } from './utilsMethods'

let apiKey = JSON.parse(localStorage.getItem('configData'))?.api_key
let storeId = JSON.parse(localStorage.getItem('configData'))?.tiendaid

export async function signUp(user, sessionHash) {

  let queryParams
  if (user.store.name === '') {
    queryParams = new URLSearchParams({
      api_key: apiKey,
      tiendaid: storeId,
      nombre: user.name,
      email: user.email,
      clave1: user.password,
      lenguage_id: 2,
      soy_cliente: 1,
      es_empresa: 0,
      sessionid: sessionHash,
    })
  } else {
    queryParams = new URLSearchParams({
      api_key: { apiKey },
      tiendaid: { storeId },
      nombre: user.name,
      email: user.email,
      clave1: user.password,
      lenguage_id: 2,
      soy_cliente: 1,
      es_empresa: 1,
      nombre_empresa: user.store.name,
      tipo_contribuyente: user.store.type,
      cuit: user.store.dni,
      sessionid: sessionHash,
    })
  }

  const response = await fetch(
    `${urlCore}php/GestionSeguridad.php?type=GSRegistracion&${queryParams}`
  )
  const data = await response.json()

  if (data.success) {
    return await logIn(user)
  } else {
    return data.mensaje
  }
}

export async function logIn(user, sessionHash, unknown) {
  var md5 = require('md5');
  const queryParams = new URLSearchParams({
    api_key: apiKey,
    tiendaid: storeId,
    user_name: user.email,
    password: md5(user.password),
    lenguage_id: 2,
    soy_cliente: 1,
    sessionid: sessionHash,
    desconocido: unknown,
  })

  const response = await fetch(
    `${urlCore}php/GestionSeguridad.php?type=GFLoginCliente&${queryParams}`
  )
  const data = await response.json()
  if (data.success) {
    return {
      id: data.idusuario_cart,
      name: data.nombreusuario_cart,
      store: data.tiendausuario,
      unknown: data.desconocido,
      iamclient: data.soycliente,
    }
  } else {
    return { error: 'Usuario o contraseña incorrecta. Inténtalo otra vez.' }
  }
}

export async function logOut(userId) {
  const queryParams = new URLSearchParams({
    OV_api_key: apiKey,
    OI_tiendaid: storeId,
    usuarioid: userId,
  })

  const response = await fetch(
    `${urlCore}php/GestionSeguridad.php?type=GFLogout&${queryParams}`
  )
  const data = await response.json()
  console.log(data)
  return data.success
}

export async function recoverPassword(email) {
  const queryParams = new URLSearchParams({
    api_key: apiKey,
    tiendaid: storeId,
    user_name: email.toLowerCase(),
    dominio: urlCore,
    soy_cliente: 1,
  })

  const response = await fetch(
    `${urlCore}php/GestionSeguridad.php?type=GFRecovery&${queryParams}`
  )
  const data = await response.json()
  if (data.success && data.mensaje !== null) {
    return data.mensaje
  } else {
    return 'Email incorrecto. Intente nuevamente.'
  }
}

export async function getProducts(page = 1, perPage = 10, productName = '') {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSListaProductos&OV_api_key=${apiKey}&tiendaid=${storeId}&paginaResultado=${perPage}&pagActual=${page}&nombreProducto=${productName}`
  )
    .then((r) => r.json())
    .then((d) => {
      let data = Object.keys(d.listaproductos).map((key) => {
        const product = d.listaproductos[key]
        let path = ''
        if (
          product.imagenes[1].file === 'noimagen.png' ||
          product.imagenes[1].error === 'Imagen Fisica, NO encontrada'
        ) {
          path = "sin imagen"
        } else {
          path =
            urlCore +
            product.imagenes[1].pathImagenProducto +
            product.productoid +
            '/' +
            product.imagenes[1].file
        }
        return {
          id: product.productoid,
          name: product.nombreproducto,
          brand: product.nombremarca,
          category: product.nombrecategoria,
          stock: product.cantidadactiva,
          image: path,
          price: product.precioVenta,
          priceNoIVA: product.preciosiniva,
          es_digital: product.es_digital,
          precioPromo: product.precioPromo
        }
      })
      return { data: data, lastPage: d.paginaUltima }
    })
}

export async function obtenterClientSession() {
  let clientSession = 0
  let unknown = getUnknown()
  let sessionId = random(15)
  return await fetch(`${urlCore}php/GestionSeguridad.php?type=GSObtenerClienteSession&api_key=${apiKey}&tiendaid=${storeId}&sessionid=${sessionId}
    `)
    .then((r) => r.json())
    .then((d) => {
      clientSession = d.clienteid
      clientSession = 0
      unknown = setUnknown(d.desconocido)
      if (clientSession == 0) {
        return registracionDesconocido(sessionId)
      }
    })
    .catch((err) => console.log(err))
}

export async function registracionDesconocido(sessionId) {
  let number = Math.floor(Math.random() * 101) + 1
  let name = 'Desconocido' + number
  let email = 'nuevo' + number + '@usuario.com'
  let clientSession = 0
  setUnknown('0')
  return await fetch(`${urlCore}php/GestionSeguridad.php?type=GSRegistracionDesconocido&api_key=${apiKey}&tiendaid=${storeId}&sessionid=${sessionId}&nombre=${name}&email=${email}
    `)
    .then((r) => r.json())
    .then((d) => {
      return {
        clientId: d.clienteid,
        unknown: d.desconocido,
        sessionId: sessionId,
      }
    })
    .catch((err) => console.log(err))
}

// PRODUCTS CART
export async function addProductToCart(
  sku,
  quantity,
  clientSession = 0,
  invoiceId = 0
) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSAgregoArticulo&OV_tiendaid=${storeId}&OV_api_key=${apiKey}&OI_clienteid=${clientSession}&OV_facturaid=${invoiceId}&OV_codigobarras=${sku}&OV_cantidad=${quantity}&OI_tipodescuentoid=0&OV_tipofactura=${invoiceType}`
  )
    .then((r) => r.json())
    .then((d) => {
      return d.facturaid
    })
    .catch((err) => console.log(err))
}
export async function getTotalProducts(clientSession) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSFacturaTotalCarrito&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&OI_clienteid=${clientSession}`
  )
    .then((r) => r.json())
    .then((d) => {
      return d.totalcarrito
    })
    .catch((err) => console.log(err))
}
export async function getCartDetail(
  clientSession,
  invoiceId = '',
  perPage = 15
) {

  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSArticuloDetalle&OV_tiendaid=${storeId}&OV_api_key=${apiKey}&OV_facturaid=${invoiceId}&OV_tipofactura=${invoiceType}&OI_clienteid=${clientSession}&pagActual=0&paginaResultado=${perPage}`
  )
    .then((r) => r.json())
    .then((d) => {
      if (d.success) {
        let products = Object.values(d.articulodetalle).filter((p) => p.productoid !== "0")
        let filter = Object.values(d.articulodetalle).filter((p) => p.productoid === "0")
        let facturaDetalleEnvioEliminar = 0
        if(filter.length > 0){
          facturaDetalleEnvioEliminar = filter[0].facturadetalleid
        }
        products = products.map((product) => {
          let path = ''
          if (
            product.imagenes[1].file === 'noimagen.png' ||
            product.imagenes[1].error === 'No encontrada'
          ) {
            path = 'img/' + 'noimagen.png'
          } else {
            path =
              product.imagenes[1].pathImagenProducto +
              product.productoid +
              '/' +
              product.imagenes[1].file
          }
          return {
            id: product.productoid,
            sku: product.codigobarras,
            name: product.nombre,
            quantity: +product.cantidad,
            image: urlCore + path,
            price: product.precioventa,
            totalPrice: product.montototal,
            invoiceId: product.facturadetalleid,
            alto: product.alto,
            ancho: product.ancho,
            peso: product.peso,
            profundidad: product.profundidad,
            medida: product.tallanom
          }
        })
        let data = {
          facturaDetalleEliminar: facturaDetalleEnvioEliminar,
          products: products,
          nextPage: d.paginaSiguiente,
          lastPage: d.paginaUltima,
          previousPage: d.paginaPrevia,
        }
        return data
      } else {
        return []
      }
    })
    .catch((err) => console.log(err))
}
export async function getEnvioId(
  clientSession,
  invoiceId = '',
  perPage = 15
) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSArticuloDetalle&OV_tiendaid=${storeId}&OV_api_key=${apiKey}&OV_facturaid=${invoiceId}&OV_tipofactura=${invoiceType}&OI_clienteid=${clientSession}&pagActual=0&paginaResultado=${perPage}`
  )
    .then((r) => r.json())
    .then((d) => {
      let envio
      if (d.success) {
        envio = Object.values(d.articulodetalle).filter((p) => p.productoid === "0")
      }
      return envio[0].facturadetalleid
    })
    .catch((err) => console.log(err))
}
export async function calculateInvoice(clientSession, facturaid) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSCalcularFactura&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&OI_clienteid=${clientSession}&OV_tipofactura=factura_pedido&OI_facturaid=${facturaid}`
  )
    .then((r) => r.json())
    .then((d) => {
      return d
    })
    .catch((err) => console.log(err))
}
export async function agregarEnvio(clientId,invoiceId,precio,iva,total) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSAgregoArticuloConcepto&OV_tiendaid=${storeId}&OV_api_key=${apiKey}&OV_facturaid=${invoiceId}&OI_clienteid=${clientId}&OV_cantidad=1&OV_tipofactura=factura_pedido&concepto_envio=ENVIO PICKIT&precio_envio=${precio}&total_envio=${total}&tasa_iva_envio=${iva}`
  )
    .then((r) => r.json())
    .then((d) => {
      return d
    })
    .catch((err) => console.log(err))
}
export async function invoiceClient(clientId, invoiceId) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSFacturaCliente&OV_tiendaid=${storeId}&OV_api_key=${apiKey}&OI_clienteid=${clientId}&OI_facturaid=${invoiceId}&OV_tipofactura=factura_pedido`
  )
    .then((r) => r.json())
    .then((d) => {
      let key = Object.keys(d.facturacliente)[0]
      let invoice = d.facturacliente[key]
      let phone = invoice.celular ? invoice.celular.replace(/-/g, '') : ""
      let direccion = Object.values(invoice.direccioncliente)
      if(direccion.length > 0){
        return {
          lastName: invoice.apellido,
          phone: phone,
          cuit: invoice.cuit,
          client: invoice.cliente,
          cuit: invoice.cuit,
          email: invoice.email,
          docType: invoice.tipodoc,
          ivaType: invoice.tipoiva,
          clienteid: invoice.clienteid,
          address: direccion[0].direccion,
          streetNumber: direccion[0].altura,
          city: direccion[0].ciudad,
          cityid: direccion[0].ciudadid,
          province: direccion[0].provincia,
          provinceid: direccion[0].provinciaid,
          postalCode: direccion[0].codigopostal,
        }
      }
      return {
        lastName: invoice.apellido,
        phone: phone,
        cuit: invoice.cuit,
        client: invoice.cliente,
        cuit: invoice.cuit,
        email: invoice.email,
        docType: invoice.tipodoc,
        ivaType: invoice.tipoiva,
        clienteid: invoice.clienteid,
        address: "No definido",
        streetNumber: "0",
        city: "No definido",
        cityid: "0",
        province: "No definido",
        provinceid: "0",
        postalCode: "0",
      }
    })
    .catch((err) => console.log(err))
}
export async function finalInvoice(userId, shipMethod = 2, payMethod, facturaid) {
  localStorage.setItem('clienteid',userId)
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSFinFactura&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&OI_facturaid=${facturaid}&OI_clienteid=${userId}&OI_tipo_envio=${shipMethod}&OI_tipo_pago=${payMethod}&url_site=${urlCore}&OV_tipofactura=factura_pedido`
  )
    .then((r) => r.json())
    .then((d) => {
      localStorage.setItem('infopedido',JSON.stringify(d))
      return d
    })
    .catch((err) => console.log(err))
}

export async function saveDataClient(info) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSGuardarDatosCliente&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&OI_clienteid=${info.clienteid}&OV_email=${info.email}&OV_telefono=${info.phone}`
  )
    .then((r) => r.json())
    .catch((err) => console.log(err))
}
export async function saveDirectClient(info) {
  console.log
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSGuardarDireccionCliente&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&OI_clienteid=${info.clienteid}&OV_direccion=${info.address}&OV_telefono=${info.phone}&NV_clientealtura=${info.streetNumber}&NV_clientecodigopostal=${info.postalCode}&NV_clienteciudad=&NI_clienteciudadid=${info.cityid}&NI_clienteprovinciaid=${info.provinceid}&NI_clientepaisid=40&NV_clienteemail=${info.email}`
  )
    .then((r) => r.json())
    .catch((err) => console.log(err))
}
export async function savePayClient(
  clientId,
  invoiceId,
  totalPay,
  paymentMethod
) {
  let paymentMethodUpdated =
    paymentMethod === 1 ? 'pago_efectivo' : 'pago_mercadopago'
  let numero
  if(totalPay.includes(".") && totalPay.includes(",")){
    let arrayTotalPay = totalPay.split(",")
    let arrayTotalPay2 = arrayTotalPay[0].split(".")
    numero = arrayTotalPay2[0]+arrayTotalPay2[1]+"."+arrayTotalPay[1]
  }else{
    if(!totalPay.includes(".") && totalPay.includes(",")){
      let arrayTotalPay = totalPay.split(",")
      numero = arrayTotalPay[0]+"."+arrayTotalPay[1]
    }
  }
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSGuardarPagoCliente&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&OI_clienteid=${clientId}&OV_tipopago=${paymentMethodUpdated}&OV_montopago=${numero}&OI_facturaid=${invoiceId}`
  )
    .then((r) => r.json())
    .catch((err) => console.log(err))
}

export async function getProductDetail(id = 0) {
  return await fetch(
    `${urlCore}php/GestionShop.php?OV_api_key=${apiKey}&type=GSDetalleProducto&tiendaid=${storeId}&productoid=${id}`
  )
    .then((r) => r.json())
    .then((p) => {
      if (p.success) {
        let key = Object.keys(p.detalleProducto)
        let path = []
        const product = p.detalleProducto[key[0]]
        let arrayImages = Object.values(product.imagenes)
        for (let x = 0; x < arrayImages.length; x++) {
          if (arrayImages[x].file === 'noimagen.png') {
            path.push(arrayImages[x].pathImagenProducto + arrayImages[x].file)
          } else {
            path.push(
              arrayImages[x].pathImagenProducto +
                arrayImages[x].productoid +
                '/' +
                arrayImages[x].file
            )
          }
        }
        return {
          id: product.productoid,
          name: product.nombreproducto,
          description: product.descripcion.replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&quot;/g, '"').toString(),
          brand: product.nombremarca,
          brandid: product.marcaid,
          category: product.categoriaid,
          stock: product.cantidadactiva,
          sku: product.codigoproducto,
          images: path,
          price: product.precioVenta,
          color: product.nombrecolor,
          talla: product.nombremedida,
          es_digital: product.es_digital,
          precioPromo: product.precioPromo,
          asociados: Object.values(product.asociados).sort(function (a, b) {
            if (a.nombrecolor > b.nombrecolor) {
              return 1;
            }
            if (a.nombrecolor < b.nombrecolor) {
              return -1;
            }
            return 0;
          })
        }
      } else {
        return {message: "Error al obtener el detalle del producto"}
      }
    })
}
export async function deleteProductCartMethod(clientSession, invoiceId, facturaid) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSArticuloDetalleBorrar&OV_api_key=${apiKey}&OI_tiendaid=${storeId}&OI_facturadetalleid=${invoiceId}&OI_clienteid=${clientSession}&OI_facturaid=${facturaid}`
  )
    .then((r) => r.json())
    .then((p) => {
      return p
    })
}
export async function deleteProducts(clientSession, invoiceId) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSVaciarCarrito&OV_api_key=${apiKey}&OV_tiendaid=${storeId}&OI_tiendaid=${storeId}&OI_vaciarcarrito=-1&OI_clienteid=${clientSession}&OI_facturaid=${invoiceId}`
  )
    .then((r) => r.json())
    .then((p) => {
      if (p.success) {
        return p
      } else {
        return 'Error al buscar el detalle del producto'
      }
    })
}
export async function cancelarCompra(pedido) {
  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTSetEstadoPedido&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&pedidoid=${pedido}&pedidoplacaestadoid=3`
  )
    .then((r) => r.json())
    .then((p) => {
      return p
    })
}

export async function cambiarMetodoPago(pedidoplacaid) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSCambiaMetodoPago&tiendaid=${storeId}&api_key=${apiKey}&tipo_pago=2&pedidoplacaid=${pedidoplacaid}`
  )
    .then((r) => r.json())
    .then((p) => {
      return p
    })
}

export async function getMostSoldProducts() {
  return await fetch(
    `${urlCore}php/GestionShop.php?OV_api_key=${apiKey}&type=GSProductoTop&tiendaid=${storeId}`
  )
    .then((r) => r.json())
    .then((d) =>
      Object.keys(d.productotop).map((key) => {
        const product = d.productotop[key]
        // IF THE PRODUCT HAVE AN IMAGE THE PATH IS DIFFERENT
        let path = ''
        if (
          product.imagenes[1].file === 'noimagen.png' ||
          product.imagenes[1].error === 'Imagen Fisica, NO encontrada'
        ) {
          path = "sin imagen"
        } else {
          path =
            urlCore +
            product.imagenes[1].pathImagenProducto +
            product.productoid +
            '/' +
            product.imagenes[1].file
        }
        return {
          id: product.productoid,
          name: product.nombreproducto,
          brand: product.nombremarca,
          category: product.nombrecategoria,
          stock: product.cantidadactiva,
          priceNoIVA: product.preciosiniva,
          precioPromo: product.precioPromo,
          price: product.precioVenta,
          image: path
        }
      })
    )
}

export async function getCategories(page = 0, perPage = 10) {

  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSListaCategorias&OV_api_key=${apiKey}&OI_tiendaid=${storeId}&nombreCategoria=&pagActual=${page}&paginaResultado=${perPage}`
  )
    .then((r) => r.json())
    .then((d) => {
      let data = Object.keys(d.listacategorias).map((key) => {
        const category = d.listacategorias[key]
        // IF THE BRAND HAVE AN IMAGE THE PATH IS DIFFERENT
        let path = ''
        if (category.imagenes[1].file === 'noimagen.png') {
          path = "sin imagen"
        } else {
          path =
            urlCore + 
            category.imagenes[1].pathImagenCategoria +
            category.imagenes[1].categoriaid +
            '/' +
            category.imagenes[1].file
        }
        return {
          id: category.categoriaid,
          name: category.nombrecategoria,
          image: path,
        }
      })
      return { data: data, lastPage: d.paginaUltima }
    })
}

export async function getCategoriesWithoutPagination(page) {

  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSListaCategorias&OV_api_key=${apiKey}&OI_tiendaid=${storeId}&nombreCategoria=&pagActual=${page}&paginaResultado=10`
  )
    .then((r) => r.json())
    .then((d) => {
      let data = Object.keys(d.listacategorias).map((key) => {
        const category = d.listacategorias[key]
        // IF THE BRAND HAVE AN IMAGE THE PATH IS DIFFERENT
        let path = ''
        if (category.imagenes[1].file === 'noimagen.png') {
          path = "sin imagen"
        } else {
          path =
            urlCore + 
            category.imagenes[1].pathImagenCategoria +
            category.imagenes[1].categoriaid +
            '/' +
            category.imagenes[1].file
        }
        return {
          id: category.categoriaid,
          name: category.nombrecategoria,
          image: path,
        }
      })
      return { data: data, paginaUltima: d.paginaUltima }
    })
}

export async function getCategoriesCarousel(page) {

  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSListaCategorias&OV_api_key=${apiKey}&OI_tiendaid=${storeId}&nombreCategoria=&pagActual=${page}&paginaResultado=8`
  )
    .then((r) => r.json())
    .then((d) => {
      let data = Object.keys(d.listacategorias).map((key) => {
        const category = d.listacategorias[key]
        // IF THE BRAND HAVE AN IMAGE THE PATH IS DIFFERENT
        let path = ''
        if (category.imagenes[1].file === 'noimagen.png') {
          path = "sin imagen"
        } else {
          path =
            urlCore + 
            category.imagenes[1].pathImagenCategoria +
            category.imagenes[1].categoriaid +
            '/' +
            category.imagenes[1].file
        }
        return {
          id: category.categoriaid,
          name: category.nombrecategoria,
          image: path,
        }
      })
      return { data: data, paginaUltima: d.paginaUltima }
    })
}

export async function getCategoriesWithoutPaginationA(page) {

  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSListaCategorias&OV_api_key=${apiKey}&OI_tiendaid=${storeId}&nombreCategoria=&pagActual=${page}&paginaResultado=16`
  )
    .then((r) => r.json())
    .then((d) => {
      let data = Object.keys(d.listacategorias).map((key) => {
        const category = d.listacategorias[key]
        // IF THE BRAND HAVE AN IMAGE THE PATH IS DIFFERENT
        let path = ''
        if (category.imagenes[1].file === 'noimagen.png') {
          path = "sin imagen"
        } else {
          path =
            urlCore + 
            category.imagenes[1].pathImagenCategoria +
            category.imagenes[1].categoriaid +
            '/' +
            category.imagenes[1].file
        }
        return {
          id: category.categoriaid,
          name: category.nombrecategoria,
          image: path,
          subcategorias: Object.values(category.subcategorias)
        }
      })
      return { data: data, paginaUltima: d.paginaUltima }
    })
}

export async function getBrands(page = 1, perPage = 10) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSListaMarcas&OV_api_key=${apiKey}&OI_tiendaid=${storeId}&nombreCategoria=&pagActual=${page}&paginaResultado=${perPage}`
  )
    .then((r) => r.json())
    .then((d) => {
      let data = Object.keys(d.listamarcas).map((key) => {
        const category = d.listamarcas[key]
        // IF THE BRAND HAVE AN IMAGE THE PATH IS DIFFERENT
        let path = ''
        if (category.imagenes[1].file === 'noimagen.png' ||
        category.imagenes[1].error === "No encontrada"
        ) {
          path = "sin imagen"
        } else {
          path =
            urlCore + 
            category.imagenes[1].pathImagenMarca +
            category.imagenes[1].marcaid +
            '/' +
            category.imagenes[1].file
        }
        return {
          id: category.marcaid,
          name: category.nombremarca,
          image: path,
        }
      })
      return { data: data, lastPage: d.paginaUltima }
    })
}

export async function getBrandsWithoutPagination(page) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSListaMarcas&OV_api_key=${apiKey}&OI_tiendaid=${storeId}&nombreCategoria=&activo=1&pagActual=${page}&paginaResultado=10`
  )
    .then((r) => r.json())
    .then((d) => {
      let data = Object.keys(d.listamarcas).map((key) => {
        const category = d.listamarcas[key]
        // IF THE BRAND HAVE AN IMAGE THE PATH IS DIFFERENT
        let path = ''
        if (category.imagenes[1].file === 'noimagen.png' ||
            category.imagenes[1].error === "No encontrada"
        ) {
          path = "sin imagen"
        } else {
          path =
            urlCore + 
            category.imagenes[1].pathImagenMarca +
            category.imagenes[1].marcaid +
            '/' +
            category.imagenes[1].file
        }
        return {
          id: category.marcaid,
          name: category.nombremarca,
          image: path,
        }
      })
      return { data: data, paginaUltima: d.paginaUltima }
    })
}

export async function getBrandsWithoutPaginationA(page) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSListaMarcas&OV_api_key=${apiKey}&OI_tiendaid=${storeId}&nombreCategoria=&activo=1&pagActual=${page}&paginaResultado=16`
  )
    .then((r) => r.json())
    .then((d) => {
      let data = Object.keys(d.listamarcas).map((key) => {
        const category = d.listamarcas[key]
        // IF THE BRAND HAVE AN IMAGE THE PATH IS DIFFERENT
        let path = ''
        if (category.imagenes[1].file === 'noimagen.png' ||
            category.imagenes[1].error === "No encontrada"
        ) {
          path = "sin imagen"
        } else {
          path =
            urlCore + 
            category.imagenes[1].pathImagenMarca +
            category.imagenes[1].marcaid +
            '/' +
            category.imagenes[1].file
        }
        return {
          id: category.marcaid,
          name: category.nombremarca,
          image: path,
        }
      })
      return { data: data, paginaUltima: d.paginaUltima }
    })
}

export async function getProductsByCategoryOrBrand(
  id,
  idSubcategory,
  page = 1,
  perPage = 10,
  isCategory = true
) {

  const categoryOrBrand = isCategory ? 'OI_categoriaid' : 'OI_marcaid'
  return await fetch(
    `${urlCore}php/GestionShop.php?OV_api_key=${apiKey}&type=GSListaProductos&${categoryOrBrand}=${id}&tiendaid=${storeId}&pagActual=${page}&paginaResultado=${perPage}&OI_subcategoriaid=${idSubcategory}`
  )
    .then((r) => r.json())
    .then((d) =>{
      let data = Object.keys(d.listaproductos).map((key) => {
        const product = d.listaproductos[key]
        // IF THE BRAND HAVE AN IMAGE THE PATH IS DIFFERENT
        let path = ''
        if (product.imagenes[1].file === 'noimagen.png') {
          path = '/img/noimagen.png'
        } else {
          path =
            product.imagenes[1].pathImagenProducto +
            product.imagenes[1].productoid +
            '/' +
            product.imagenes[1].file
        }
        return {
          id: product.productoid,
          name: product.nombreproducto,
          brand: product.nombremarca,
          category: product.nombrecategoria,
          stock: product.cantidadactiva,
          image: urlCore + path,
          price: product.precioVenta,
          priceNoIVA: product.preciosiniva,
          precioPromo: product.precioPromo
        }
      })
      return { data: data, lastPage: d.paginaUltima }
    })
}

export async function getImagesProduct(idProduct) {
  return await fetch(
    `${urlCore}php/GestionImagen.php?type=GIListarImagenProductoDB&productoid=${idProduct}`
  )
    .then((r) => r.json())
    .then((d) => {
      if (Object.keys(d.listaimagenes).length > 0) {
        let images = Object.keys(d.listaimagenes).map((key) => {
          const image = d.listaimagenes[key]
          return image.pathImagenProducto + image.productoid + '/' + image.file
        })
        return images
      } else {
        return []
      }
    })
}

export async function getOrders(clientId) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSMisPedidos&OV_tiendaid=${storeId}&OV_api_key=${apiKey}&OI_clienteid=${clientId}&pagActual=1&paginaResultado=10`
  )
    .then((r) => r.json())
    .then((d) => {
      let orders = Object.keys(d.pedidos).map((orderKey) => {
        return d.pedidos[orderKey]
      })
      let ordersSort = orders.sort(function (a, b) {
        if (parseInt(a.pedidoplacaid) < parseInt(b.pedidoplacaid)) {
          return 1;
        }
        if (parseInt(a.pedidoplacaid) > parseInt(b.pedidoplacaid)) {
          return -1;
        }
        return 0;
      });
      return ordersSort
    })
}

export async function getOrderDetail(orderId, clientId) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSMiPedido&OV_tiendaid=${storeId}&OV_api_key=${apiKey}&OI_facturaid=${orderId}&OI_clienteid=${clientId}`
  )
    .then((r) => r.json())
    .then((d) => d.pedido)
}

export async function getDataStore() {
  return await fetch(
    `${urlCore}php/GestionShop.php?OV_api_key=${apiKey}&type=GSParametroTienda&OI_tiendaid=${storeId}`
  )
    .then((r) => r.json())
    .then((d) => d.parametro)
}

export async function getDataStore2() {
  return await fetch(
    `${urlCore}php/GestionShop.php?OV_api_key=${apiKey}&type=GSDatosTienda&tiendaid=${storeId}`
  )
    .then((r) => r.json())
    .then((d) => d)
}

export async function getStores() {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSListaTiendas&OV_api_key=${apiKey}&OI_tiendaid=${storeId}`
  )
    .then((r) => r.json())
    .then((d) => {
      let stores = Object.keys(d.listatiendas).map((key) => {
        return d.listatiendas[key]
      })
      return stores
    })
}

export async function setGSPagoMP(
  tiendaid,
  clienteid,
  pedidoid,
  facturaid,
  url_mp,
  api_key,
  dominio,
  paginaStatus
) {

  if (url_mp !== '') {
    try {
      const data = new URLSearchParams()
      data.append('tiendaId', tiendaid)
      data.append('clienteId', clienteid)
      data.append('pedidoId', pedidoid)
      data.append('facturaId', facturaid)
      data.append('api_key', api_key)
      data.append('dominiocart', dominio)
      data.append('urlstatusMP', paginaStatus)

      const response = await fetch(url_mp, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: data,
      })

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`)
      }
      const responseData = await response.json()
      const redirect_url = responseData.url
      location.href = redirect_url
      return responseData
    } catch (err) {
      console.error('Error occurred:', err)
      // Handle the error gracefully, e.g., show an error message to the user
    }
  } else {
    console.log('falla la API de MP')
  }
}

export async function getConfigStore(dominio) {
  let dominioUpdated = dominio.includes('localhost')
    ? 'devel.gestionfactura.com'
    : dominio
  dominioUpdated = dominioUpdated.replace(/^(https?:\/\/)/, '')
  dominioUpdated = dominioUpdated.replace(/(^\/)|(\/$)/g, '')
  console.log(dominioUpdated)
  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTGetDatosTienda&dominio=${dominioUpdated}`
  )
    .then((r) => r.json())
    .then((d) => {
      apiKey = d.api_key
      storeId = d.tiendaid
      return d
    })
}

//---------------------- COTIZAR ENVIO A DOMICILIO ---------------------------------------

export async function cotizarEnvioADomicilio(info) {

  let token = JSON.parse(localStorage.getItem('dataStore2')).pickittoken
  let apikey = JSON.parse(localStorage.getItem('dataStore2')).pickitkey
  let productosAux = JSON.parse(localStorage.getItem('listadoproductos'))
  let retailer = JSON.parse(localStorage.getItem('dataStore2'))

  let productos = []
  let numero 
  for(let i=0; i < productosAux.length; i++){
    if(productosAux[i].price.includes(",") && productosAux[i].price.includes(".")){
      let array = productosAux[i].price.split(",")
      let array2 = array[0].split(".")
      numero = array2[0]+array2[1]+"."+array[1]
    }else{
      if(productosAux[i].price.includes(",") && !productosAux[i].price.includes(".")){
        let array = productosAux[i].price.split(",")
        numero = array[0]+"."+array[1]
      }
    }
    productos.push({
      name: productosAux[i].name,
      weight: {
        amount: 1000,
        unit: "g"
      },
      length: {
        amount: parseInt(productosAux[i].ancho),
        unit: "cm",
      },
      height: {
        amount: parseInt(productosAux[i].alto),
        unit: "cm"
      },
      width: {
        amount: parseInt(productosAux[i].profundidad),
        unit: "cm"
      },
      price: parseFloat(numero),
      sku: productosAux[i].sku,
      amount: parseInt(productosAux[i].quantity),
    })
  }

  var data = JSON.stringify({
    token: token,
    apiKey: apikey,
    products: productos,
    sandbox: sandbox,
    pais: pais,
    retailer: {
      address: retailer.direccion,
      streetNumber: retailer.altura,
      province: retailer.provincia,
      postalCode: retailer.codigopostal,
      city: retailer.ciudad
    },
    customer: {
      name: info.client,
      lastName: info.lastName,
      pid: info.cuit,
      email: info.email,
      phone: info.phone,
      address: info.address,
      streetNumber: info.streetNumber,
      province: info.province,
      postalCode: info.postalCode,
      city: info.city
    }
  });

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: data,
    redirect: 'follow'
  };

  return await fetch(
    `${urlPickit}api/cotizarDomicilio`,
    requestOptions
    )
    .then((r) => r.json())
    .then((d) => {
      localStorage.setItem('infoCotizacionDomicilio', JSON.stringify(d))
      return d
    })
}

// -------------------------- COTIZAR ENVIO --------------------------
export async function cotizarEnvio(info, pointid) {
  let token = JSON.parse(localStorage.getItem('dataStore2')).pickittoken
  let apikey = JSON.parse(localStorage.getItem('dataStore2')).pickitkey
  let productosAux = JSON.parse(localStorage.getItem('listadoproductos'))
  let retailer = JSON.parse(localStorage.getItem('dataStore2'))

  let productos = []
  let numero 
  for(let i=0; i < productosAux.length; i++){
    if(productosAux[i].price.includes(",") && productosAux[i].price.includes(".")){
      let array = productosAux[i].price.split(",")
      let array2 = array[0].split(".")
      numero = array2[0]+array2[1]+"."+array[1]
    }else{
      if(productosAux[i].price.includes(",") && !productosAux[i].price.includes(".")){
        let array = productosAux[i].price.split(",")
        numero = array[0]+"."+array[1]
      }
    }
    productos.push({
      name: productosAux[i].name,
      weight: {
        amount: 1000,
        unit: "g"
      },
      length: {
        amount: parseInt(productosAux[i].ancho),
        unit: "cm",
      },
      height: {
        amount: parseInt(productosAux[i].alto),
        unit: "cm"
      },
      width: {
        amount: parseInt(productosAux[i].profundidad),
        unit: "cm"
      },
      price: parseFloat(numero),
      sku: productosAux[i].sku,
      amount: parseInt(productosAux[i].quantity),
    })
  }

  var data = JSON.stringify({
    token: token,
    apiKey: apikey,
    pointId: pointid,
    sandbox: sandbox,
    pais: pais,
    products: productos,
    retailer: {
      address: retailer.direccion,
      streetNumber: retailer.altura,
      province: retailer.provincia,
      postalCode: retailer.codigopostal,
      city: retailer.ciudad
    },
    customer: {
      name: info.client,
      lastName: info.lastName,
      pid: info.cuit,
      email: info.email,
      phone: info.phone,
      address: info.address,
      streetNumber: info.streetNumber,
      province: info.province,
      postalCode: info.postalCode,
      city: info.city
    }
  });

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: data,
    redirect: 'follow'
  };

  return await fetch(
    `${urlPickit}api/cotizar`,
    requestOptions
    )
    .then((r) => r.json())
    .then((d) => {
      localStorage.setItem('infoCotizacion', JSON.stringify(d))
      return d
    })
}

//-------------------------- GET PUNTOS DE RETIRO -----------------------------
export async function getPuntosDeRetiro(cp) {

  let token = JSON.parse(localStorage.getItem('dataStore2')).pickittoken
  let apikey = JSON.parse(localStorage.getItem('dataStore2')).pickitkey
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "token": token,
    "apiKey": apikey,
    "sandbox": sandbox,
    "pais": pais,
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return await fetch(`${urlPickit}api/listadoPuntos?codigoPostal=${cp}`, requestOptions)
    .then((r) => r.json())
    .then((d) => {
      let array = []
      if(d.length > 0){
        array = d.map(p => {
          return {
            id: p.id,
            address: p.address,
            name: p.name,
            schedule: newSchedule(p.schedule),
            lat: p.lat,
            lng: p.lng
          }
        })
      }
      return {
        data: array, 
        loading: false, 
        message: "No existen puntos de retiro para el codigo postal ingresado. Seleccione Envio a Domicilio"
      }
    })
    .catch(() => {
      return {
        data: [], 
        loading: false, 
        message: "Hubo un problema al obtener los puntos."
      }
    });
}

//-------------------------- GET PUNTO BY ID -----------------------------
export async function getPuntoDeRetiroById(id) {

  let token = JSON.parse(localStorage.getItem('dataStore2')).pickittoken
  let apikey = JSON.parse(localStorage.getItem('dataStore2')).pickitkey
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "token": token,
    "apiKey": apikey,
    "sandbox": sandbox,
    "pais": pais,
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  return await fetch(`${urlPickit}api/listadoPuntos?id=${id}`, requestOptions)
    .then((r) => r.json())
    .then((d) => {
      return d[0]
    })
}

// -------------------------- GET PAISES -----------------------------
export async function getPaises() {
  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTGetPaises&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&paisid=0&mercadolibreid=0&nombrepais=`
  )
    .then((r) => r.json())
    .then((d) => Object.values(d.pais))
}

//--------------------------- GET PROVINCIAS --------------------------
export async function getProvincias(paisid) {
  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTGetProvincias&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&paisid=${paisid}&provinciaid=0&mercadolibreid=0&nombreprovincia=`
  )
    .then((r) => r.json())
    .then((d) => Object.values(d.provincia))
}

//--------------------------- GET PROVINCIAS --------------------------
export async function getProvinciaById(paisid,provinciaid) {
  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTGetProvincias&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&paisid=${paisid}&provinciaid=${provinciaid}&mercadolibreid=0&nombreprovincia=`
  )
    .then((r) => r.json())
    .then((d) => Object.values(d.provincia)[0].nombre)
}

//---------------------------- GET CIUDADES ----------------------------
export async function getCiudades(paisid,provinciaid) {
  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTGetLocalidades&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&paisid=${paisid}&provinciaid=${provinciaid}&nombrelocalidad=&codigopostal=`
  )
    .then((r) => r.json())
    .then((d) => Object.values(d.localidad).sort(function (a, b) {
      if (a.nombrelocalidad > b.nombrelocalidad) {
        return 1;
      }
      if (a.nombrelocalidad < b.nombrelocalidad) {
        return -1;
      }
      return 0;
    }))
}

//---------------------------- GET CIUDAD BY ID ----------------------------
export async function getCiudadByid(paisid,provinciaid,localidadid) {
  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTGetLocalidades&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&paisid=${paisid}&provinciaid=${provinciaid}&localidadid=${localidadid}&nombrelocalidad=&codigopostal=`
  )
    .then((r) => r.json())
    .then((d) => Object.values(d.localidad)[0].nombrelocalidad)
}

//---------------------------- GENERAR TRANSACCION ENVIO A PUNTO ----------------------
export async function generarTransaccionEnvioAPunto() {
  let uuid = JSON.parse(localStorage.getItem('infoCotizacion')).uuid
  let infopedido = JSON.parse(localStorage.getItem('infopedido')).pedidoid

  let token = JSON.parse(localStorage.getItem('dataStore2')).pickittoken
  let apikey = JSON.parse(localStorage.getItem('dataStore2')).pickitkey
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "token": token,
    "apiKey": apikey,
    "order": infopedido,
    "sandbox": sandbox,
    "pais": pais,
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return await fetch(
    `${urlPickit}api/transaccion/${uuid}`, requestOptions
  )
    .then((r) => r.json())
    .then((d) => {
      localStorage.setItem('infoTransaccion',JSON.stringify(d))
    })
    .catch((err) => console.log(err))
}

//---------------------------- GENERAR TRANSACCION ENVIO A DOMICILIO ----------------------
export async function generarTransaccionEnvioDomicilio() {
  let uuid = JSON.parse(localStorage.getItem('infoCotizacionDomicilio')).uuid
  let infopedido = JSON.parse(localStorage.getItem('infopedido')).pedidoid

  let token = JSON.parse(localStorage.getItem('dataStore2')).pickittoken
  let apikey = JSON.parse(localStorage.getItem('dataStore2')).pickitkey
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "token": token,
    "apiKey": apikey,
    "order": infopedido,
    "sandbox": sandbox,
    "pais": pais,
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return await fetch(
    `${urlPickit}api/transaccion/${uuid}`, requestOptions
  )
    .then((r) => r.json())
    .then((d) => {
      localStorage.setItem('infoTransaccionADomicilio',JSON.stringify(d))
    })
    .catch((err) => console.log(err))
}

//--------------------------- GUARDAR DATOS ENVIO A DOMICILIO--------------------------------
export async function guardarDatosPedidoADomicilio(dataEnvio) {
  let infoCotizacion = JSON.parse(localStorage.getItem('infoCotizacionDomicilio'))
  let pedidoid = JSON.parse(localStorage.getItem('infopedido')).pedidoid
  let clienteid = localStorage.getItem('clienteid')
  let infoTransaccion = JSON.parse(localStorage.getItem('infoTransaccionADomicilio'))

  return await fetch(
    `${urlCore}php/GestionShop.php?type=GTSetPickitCliente&tiendaid=${storeId}&api_key=${apiKey}&clientepickitid=0&clienteid=${clienteid}&pedidoid=${pedidoid}&uuid=${infoCotizacion.uuid}&costo_envio=${infoCotizacion.price}&impuesto_envio=${infoCotizacion.tax}&total_envio=${infoCotizacion.totalPrice}&transactionid=${infoTransaccion.transactionId}&pickit_code=${infoTransaccion.pickitCode}&url_tracking=${infoTransaccion.urlTracking}&nombre=${dataEnvio.client}&apellido=${dataEnvio.lastName}&email=${dataEnvio.email}&telefono=${dataEnvio.phone}&calle=${dataEnvio.address}&altura=${dataEnvio.streetNumber}&localidadid=${dataEnvio.cityid}&provinciaid=${dataEnvio.provinceid}&codigopostal=${dataEnvio.postalCode}&origen_envio=2&punto_envio=`
  )
  .then((r) => r.json())
  .then((d) => {
    return d
  })
  .catch((err) => console.log(err))
}

//--------------------------- GUARDAR DATOS ENVIO A PUNTO--------------------------------
export async function guardarDatosPedidoAPunto(dataEnvio,punto) {
  let infoCotizacion = JSON.parse(localStorage.getItem('infoCotizacion'))
  let pedidoid = JSON.parse(localStorage.getItem('infopedido')).pedidoid
  let clienteid = localStorage.getItem('clienteid')
  let infoTransaccion = JSON.parse(localStorage.getItem('infoTransaccion'))

  return await fetch(
    `${urlCore}php/GestionShop.php?type=GTSetPickitCliente&tiendaid=${storeId}&api_key=${apiKey}&clientepickitid=0&clienteid=${clienteid}&pedidoid=${pedidoid}&uuid=${infoCotizacion.uuid}&costo_envio=${infoCotizacion.price}&impuesto_envio=${infoCotizacion.tax}&total_envio=${infoCotizacion.totalPrice}&transactionid=${infoTransaccion.transactionId}&pickit_code=${infoTransaccion.pickitCode}&url_tracking=${infoTransaccion.urlTracking}&nombre=${dataEnvio.client}&apellido=${dataEnvio.lastName}&email=${dataEnvio.email}&telefono=${dataEnvio.phone}&calle=${punto.street}&altura=${dataEnvio.streetNumber}&localidadid=${dataEnvio.cityid}&provinciaid=${dataEnvio.provinceid}&codigopostal=${dataEnvio.postalCode}&origen_envio=1&punto_envio=${punto.id}`
  )
  .then((r) => r.json())
  .then((d) => {
    return d
  })
  .catch((err) => console.log(err))
}

//----------------------------- OBTENER DATOS PEDIDO ------------------------
export async function getDatosPedido(clientepickitid,clienteid) {

  return await fetch(
    `${urlCore}php/GestionShop.php?type=GTGetPickitCliente&tiendaid=${storeId}&api_key=${apiKey}&clientepickitid=${clientepickitid}&clienteid=${clienteid}`
  )
  .then((r) => r.json())
  .then((d) => {
    return d
  })
  .catch((err) => console.log(err))
}

//------------------------------ GET INFO CLIENTE --------------------------------
export async function getInfoCliente(clienteid) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GTGetClientes&OV_api_key=${apiKey}&OI_tiendaid=${storeId}&OI_clienteid=${clienteid}&paginaActual=&paginaResultado=&OV_nombre=&NV_cuit=&OV_email=`
  )
    .then((r) => r.json())
    .then((d) => {
      let info = d.clientes[clienteid]
      let direccioncliente = Object.values(info.direccioncliente)
      if(direccioncliente.length > 0){
        info.provinciaid = direccioncliente[0].provinciaid || ''
        info.ciudadid = direccioncliente[0].ciudadid || ''
        info.direccion = direccioncliente[0].direccion || ''
        info.altura = direccioncliente[0].altura || ''
        info.codigopostal = direccioncliente[0].codigopostal || ''
      }
      return info
    })
    .catch((err) => console.log(err))
}

//------------------------------ EDITAR CLIENTE -----------------------------------
export async function editarCliente(info,isClient,password) {
  let esPersona = isClient ? "1" : "0"
  var md5 = require('md5');
  let claveEncriptada = password !== '' ? md5(password) : password

  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTSetCliente&OV_api_key=${apiKey}&OI_tiendaid=${storeId}&fisica_juridica=${esPersona}&clienteid=${info.clienteid}&nombre=${info.nombre}&apellido=${info.apellido}&tipodocumentoid=${info.tipodocidentidadid}&numerodocumento=${info.cuit}&celular=${info.celular}&telefono=${info.telefono}&email=${info.email}&tipocondicioniva=${info.tipocondicionivaid}&tipocondicionpago=${info.tipocondicionpagoid}&clave=${claveEncriptada}`
  )
    .then((r) => r.json())
    .then((d) => {
      return d
    })
    .catch((err) => console.log(err))
}
//------------------------------ GUARDAR DATOS DIRECCION CLIENTE ------------------------------
export async function guardarDatosDireccion(info) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSGuardarDireccionCliente&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&OI_clienteid=${info.clienteid}&OV_direccion=${info.direccion}&OV_telefono=${info.telefono}&NV_clientealtura=${info.altura}&NV_clientecodigopostal=${info.codigopostal}&NV_clienteciudad=${info.ciudad}&NI_clienteciudadid=${info.ciudadid}&NI_clienteprovinciaid=${info.provinciaid}&NI_clientepaisid=40&NV_clienteemail=${info.email}`
  )
    .then((r) => r.json())
    .catch((err) => console.log(err))
}
//------------------------------ TIPOS DE DOCUMENTO -----------------------------------
export async function getTiposDocumento() {
  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTGetTipoDocumento&OV_api_key=${apiKey}&OI_tiendaid=${storeId}`
  )
    .then((r) => r.json())
    .then((d) => {
      return Object.values(d.tipo_doc)
    })
    .catch((err) => console.log(err))
}
//------------------------------ TIPOS CONDICION DE IVA -----------------------------------
export async function getTiposCondicionIva() {
  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTGetCondicionIva&OV_api_key=${apiKey}&OI_tiendaid=${storeId}`
  )
    .then((r) => r.json())
    .then((d) => {
      return Object.values(d.cond_iva)
    })
    .catch((err) => console.log(err))
}

//------------------------------ TIPOS CONDICION DE PAGO -----------------------------------
export async function getTiposCondicionPago() {
  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTGetCondicionesPago&OV_api_key=${apiKey}&OI_tiendaid=${storeId}`
  )
    .then((r) => r.json())
    .then((d) => {
      return Object.values(d.cond_pago)
    })
    .catch((err) => console.log(err))
}

//------------------------------ GET TALLAS --------------------------------------------------
export async function getTallas() {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSListaTallas&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&OI_tallaid=0&trae_todo=0&activo=1`
  )
    .then((r) => r.json())
    .then((d) => {
      return Object.values(d.listatallas)
    })
    .catch((err) => console.log(err))
}

export async function getProductosHome() {
  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTGetProductosHome&tiendaid=${storeId}&api_key=${apiKey}`
  )
    .then((r) => r.json())
    .then((d) => {
      let path = ''
      let info = Object.values(d.productoshome).map((p) =>{
        if (
          p.imagenes[1].file === 'noimagen.png' ||
          p.imagenes[1].error === 'Imagen Fisica, NO encontrada'
        ) {
          path = "sin imagen"
        } else {
          path =
            urlCore +
            p.imagenes[1].pathImagenProducto +
            p.productoid +
            '/' +
            p.imagenes[1].file
        }
        return {
          codigoarticulo: p.codigoarticulo,
          nombre: p.nombre,
          preciopromo: p.preciopromo,
          preciosiniva: p.preciosiniva,
          preciosinivapromo: p.preciosinivapromo,
          precioventa: p.precioventa,
          producto_homeid: p.producto_homeid,
          productoid: p.productoid,
          tiendaid: p.tiendaid,
          imagen: path
        }
      })
    return info  
    })
    .catch((err) => console.log(err))
}

//----------------------- NEWSLETTER ------------------------
export async function postEmailNewsletter(email) {

  // const myHeaders = new Headers();
  // myHeaders.append("api_key", apiKey);

  const formdata = new FormData();
  formdata.append("type", "GTSetNewsletter");
  formdata.append("tiendaid", storeId)
  formdata.append("newsletterid", "0");
  formdata.append("email", email);
  formdata.append("api_key", apiKey);

  const options = {
    method: "POST",
    // headers: myHeaders,
    body: formdata,
  };

  return await fetch(
    `${urlCore}php/GestionTienda.php`, options)
    .then((r) => r.json())
    .then((d) => {
      return d
    })
    .catch((err) => {
      console.log(err)
      return {mensaje: "Hubo un error al registrar el email."}
    })
}

//--------------------- MENSAJES ---------------------
export async function getMensajes(pedidoid) {
  let usuarioid = JSON.parse(localStorage.getItem('user')).id
  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTGetMensajes&tiendaid=${storeId}&api_key=${apiKey}&usuarioid=${usuarioid}&usuariodestinoid=&pedidoid=${pedidoid}&mensajeid=&mensaje&pagActual=1&paginaResultado=15`
  )
    .then((r) => r.json())
    .then((d) => {

      let info = Object.values(d.mensajes).map((m) => {
        let aux = m.fechaingreso.split(' ')
        let auxFecha = aux[0].split('-')
        return {
          fecha: auxFecha[2]+'-'+auxFecha[1]+'-'+auxFecha[0],
          hora: aux[1].slice(0,5),
          mensaje: m.descripcion,
          usuario: m.usuarioid
        }
      })
      return info
    })
    .catch((err) => console.log(err))
}

export async function sendMessage(info) {
  let usuarioid = JSON.parse(localStorage.getItem('user')).id

  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTSetMensaje&tiendaid=${storeId}&api_key=${apiKey}&usuarioid=${usuarioid}&usuariodestino=&mensajeid=0&mensaje=${info.mensaje}&titulo=${info.titulo}&leido=0&pedidoid=${info.pedidoid}`
  )
    .then((r) => r.json())
    .then((d) => {
      return d
    })
    .catch((err) => console.log(err))
}

//--------------------- MENSAJES PRODUCTOS ---------------------
export async function getMensajesProducto(productoid, usuarioid) {

  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTGetMensajes&tiendaid=${storeId}&api_key=${apiKey}&usuarioid=${usuarioid.id}&usuariodestinoid=&productoid=${productoid}&mensajeid=&mensaje&pagActual=1&paginaResultado=15`
  )
    .then((r) => r.json())
    .then((d) => {

      let info = Object.values(d.mensajes).filter((p) => p.leido === "1").map((m) => {
        let aux = m.fechaingreso.split(' ')
        let auxFecha = aux[0].split('-')
        return {
          fecha: auxFecha[2]+'-'+auxFecha[1]+'-'+auxFecha[0],
          hora: aux[1].slice(0,5),
          mensaje: m.descripcion,
          usuario: m.usuarioid,
          titulo: m.titulo
        }
      })
      return info
    })
    .catch((err) => console.log(err))
}

export async function sendMessageProducto(info) {
  let usuarioid = JSON.parse(localStorage.getItem('user')).id

  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTSetMensaje&tiendaid=${storeId}&api_key=${apiKey}&usuarioid=${usuarioid}&usuariodestino=&mensajeid=0&mensaje=${info.mensaje}&titulo=${info.titulo}&leido=0&productoid=${info.productoid}`
  )
    .then((r) => r.json())
    .then((d) => {
      return d
    })
    .catch((err) => console.log(err))
}

//-------------------------- SUBCATEGORIAS --------------------------
export async function getSubcategorias(idcategoria) {
  return await fetch(
    `${urlCore}php/GestionTienda.php?type=GTListaCategorias&OI_tiendaid=${storeId}&OV_api_key=${apiKey}&OI_categoriaid=${idcategoria}`
  )
    .then((r) => r.json())
    .then((d) => {
      return Object.values(Object.values(d.listacategorias)[0].listasubcategorias).filter((f) => f.activo === "1")

    })
    .catch((err) => console.log(err))
}

//------------------------- INFO ENTREGA --------------------------
export async function agregarInfoEntrega(pedidoid,fecha_entrega,hora_desde,hora_hasta,franja) {
  return await fetch(
    `${urlCore}php/GestionShop.php?type=GSSetInfoEntrega&api_key=${apiKey}&tiendaid=${storeId}&pedidoplacaenvioid=0&pedidoid=${pedidoid}&fecha_entrega=${fecha_entrega}&hora_desde=${hora_desde}&hora_hasta=${hora_hasta}&franja=${franja}`
  )
    .then((r) => r.json())
    .then((d) => {
      return d

    })
    .catch((err) => console.log(err))
}