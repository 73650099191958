import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { UserCircle } from '@phosphor-icons/react'

import { logOut, setClientSession } from './../actions/index.js'
import {
  logOut as logOutMethod,
  obtenterClientSession,
} from './../methods/index.js'
import useComponentVisible from '../utilities/UseComponentVisible.jsx'

import { Toaster, toast } from 'sonner'
import { useState } from 'react'

export const ProfileMenu = ({ user }) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

    let dataStore = useSelector((state) => state.dataStore)
    let colorFondoSubNav = dataStore?.ecommerce_colorFondoSubNav === "#454545" ? "#0661f4" : dataStore?.ecommerce_colorFondoSubNav

    const [colorHover, setColorHover] = useState({
      email: false,
      cart: false, 
      editar: false,
      pedidos: false,
      salir: false,
      ingresar: false,
      registrar: false
    })

  const navigate = useNavigate()
  const dispatch = useDispatch()

  function handleMyOrders() {
    setIsComponentVisible(false)
    navigate('./mis-pedidos')
  }

  function handleEditProfile() {
    setIsComponentVisible(false)
    navigate('./editar-perfil')
  }

  async function handleLogOut() {
    const isLogOut = await logOutMethod(user.id)
    if (isLogOut) {
      dispatch(setClientSession(await obtenterClientSession()))
      dispatch(logOut())
      setIsComponentVisible(false)
      toast(
        <div className="flex flex-col gap-2 text-[13px]">
          <p>Te has deslogueado exitosamente</p>
        </div>
      )
      navigate('/')
    }
  }

  return (
    <div className="relative" ref={ref}>
      <Toaster />
      {/* NAVBAR */}
      <div
        className={
          isComponentVisible
            ? 'min-w-[120px] font-bold w-max flex gap-2 justify-items-end items-center py-1 px-2 rounded-[5px] bg-white text-black hover:cursor-pointer'
            : 'min-w-[120px] font-bold w-max flex gap-2 justify-items-end items-center py-1 px-2 rounded-[5px] hover:cursor-pointer hover:bg-white hover:text-black duration-200'
        }
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <UserCircle size={36} />
        <p className="text-center">
          {user.name.length > 16 ? user.name.slice(0, 16) + '...' : user.name}
        </p>
      </div>
      {/* MENU PROFILE */}
      {isComponentVisible && (
        <div className="flex flex-col items-center gap-2 text-white z-10 absolute top-[61px] left-[0px] bg-white p-2 rounded shadow-xl border-1 border-gray-01 w-[100%] min-w-[120px]">
          <button
            className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
            onClick={() => handleEditProfile()}
            style={{color: colorHover.editar && colorFondoSubNav}}
            onMouseEnter={() => 
              setColorHover({
                email: false,
                cart: false, 
                editar: true,
                pedidos: false,
                salir: false,
                ingresar: false,
                registrar: false
              })
            }
          >
            Editar perfil
          </button>
          <button
            className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
            onClick={() => handleMyOrders()}
            style={{color: colorHover.pedidos && colorFondoSubNav}}
            onMouseEnter={() => 
              setColorHover({
                email: false,
                cart: false, 
                editar: false,
                pedidos: true,
                salir: false,
                ingresar: false,
                registrar: false
              })
            }
          >
            Mis pedidos
          </button>
          <button
            className="border-1 border-black bg-black p-2 text-sm rounded-[5px] w-full hover:bg-white0 hover:text-blue duration-200"
            onClick={() => handleLogOut()}
            style={{color: colorHover.salir && colorFondoSubNav}}
            onMouseEnter={() => 
              setColorHover({
                email: false,
                cart: false, 
                editar: false,
                pedidos: false,
                salir: true,
                ingresar: false,
                registrar: false
              })
            }
          >
            Salir
          </button>
        </div>
      )}
    </div>
  )
}
