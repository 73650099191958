import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import { UserCircle } from '@phosphor-icons/react'

import { 
    editarCliente, 
    getCiudadByid, 
    getCiudades, 
    getInfoCliente, 
    getProvinciaById, 
    getProvincias, 
    getTiposCondicionIva, 
    getTiposCondicionPago, 
    getTiposDocumento, 
    guardarDatosDireccion
} from '../methods'

import { Toaster, toast } from 'sonner'

import { UseScrollToTop } from './../utilities/UseScrollToTop'
import { ModalMaintenance } from './ModalMaintenance'

export default function EditProfile() {

  let clienteid = useSelector((state) => state.user)

  let dataStore = useSelector((state) => state.dataStore)
  let colorFondoSubNav = dataStore?.ecommerce_colorFondoSubNav === "#454545" ? "#0661f4" : dataStore?.ecommerce_colorFondoSubNav
  let enMantenimiento = dataStore?.default_mantenimiento  

  const [user, setUser] = useState('')
  const [documentos, setDocumentos] = useState('')
  const [tipoIva, setTipoIva] = useState('')
  const [tipoPago, setTipoPago] = useState('')
  const [provincias, setProvincias] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isClient, setIsClient] = useState(true)
  const [localidades, setLocalidades] = useState([])
  const [actualizarDireccion, setActualizarDireccion] = useState(false)

  const [error, setError] = useState({
    nombre: false,
    apellido: false,
    altura: false,
    codigopostal: false,
    telefono: false,
    celular: false
  })

  const handleChange = (e) => {
    e.preventDefault()

    const regex = /\d/

    if(e.target.name === "nombre" || e.target.name === "apellido"){
        let aux = regex.test(e.target.value)
        setError({
            ...error,
            [e.target.name]: aux
        })
    }

    if(e.target.name === "altura" ||
    e.target.name === "celular" || e.target.name === "telefono" || 
    e.target.name === "cuit"
    ){
        setError({
            ...error,
            [e.target.name]: isNaN(e.target.value)
        })
    }

    setUser({
        ...user,
        [e.target.name]: e.target.value,
    })
}

  useEffect(() =>{
    ;(async () => {
        let datos = await getInfoCliente(clienteid.id)
        setUser(datos)
        let tiposDoc = await getTiposDocumento()
        setDocumentos(tiposDoc)
        let tiposIva = await getTiposCondicionIva()
        setTipoIva(tiposIva)
        let tiposPago = await getTiposCondicionPago()
        setTipoPago(tiposPago)
        let provinciaAux = await getProvincias('40')
        setProvincias(provinciaAux)
        let auxLocalidades = await getCiudades('40',user.provinciaid)
        setLocalidades(auxLocalidades)
    })()
  },[])

  useEffect(() => {
    ;(async () => {
        let auxLocalidades = await getCiudades('40',user.provinciaid)
        setLocalidades(auxLocalidades)
    })()
  },[user.provinciaid])

  async function handleEditProfile() {
    var x = user.cuit;
    var y = 0;
    x.split('').forEach(x => y += parseInt(x));

    if(password === confirmPassword){
        if( user.nombre === '' || user.email === '' || 
        user.tipodocidentidadid === '' || user.telefono === '' ||
        user.celular === '' || user.cuit === ''
        ){
            toast(
                <div className="flex flex-col gap-2 text-[16px]">
                <p>Debe completar todos los campos.</p>
                </div>
            )
        }else{

        if(y > 0){
            let nombreCiudad = await getCiudadByid('40', user.provinciaid,user.ciudadid)
        user.ciudad = nombreCiudad
        let response = await editarCliente(user,isClient,password)
        let response2 
        if(actualizarDireccion){
            if(user.provinciaid === '' ||
                user.ciudadid === '' || user.direccion === '' || user.altura === ''){
                    toast(
                        <div className="flex flex-col gap-2 text-[16px]">
                        <p>Debe completar todos los campos para la direcci&oacute;n.</p>
                        </div>
                    )
                }else{
                    response2 = await guardarDatosDireccion(user)
                    if(response.success === true && response2.success === true){
                        toast(
                            <div className="flex flex-col gap-2 text-[16px]">
                            <p>Se actualizaron correctamente los datos.</p>
                            </div>
                        )
                    }else{
                        if(response.success === false){
                            toast(
                                <div className="flex flex-col gap-2 text-[16px]">
                                <p>{response.mensaje}</p>
                                </div>
                            )
                        }else{
                            toast(
                                <div className="flex flex-col gap-2 text-[16px]">
                                <p>{response2.mensaje}</p>
                                </div>
                            )
                        }
                    }}
                }else{
                    toast(
                        <div className="flex flex-col gap-2 text-[16px]">
                        <p>{response.mensaje}</p>
                        </div>
                    )
                }
            }else{
                toast(
                    <div className="flex flex-col gap-2 text-[16px]">
                        <p>Debe ingresar un n&uacute;mero de documento v&aacute;lido.</p>
                    </div>
                )
            }
        }
        
    }else{
        toast(
            <div className="flex flex-col gap-2 text-[16px]">
                <p>Las claves no coinciden.</p>
            </div>
        )
    }
  }

  return (
    <div className="w-[80vw] mx-auto mt-12">
      <ModalMaintenance
        show={enMantenimiento}
      />
      <UseScrollToTop />
      <Toaster />
      <p className="text-[13px]">
        INICIO / <span className="font-bold text-[16px]">EDITAR PERFIL</span>
      </p>
      <div className="mx-auto my-20 flex justify-center flex-col items-center">
        <UserCircle
          className="bg-black p-2 rounded-full text-white"
          size={60}
        />
        <label className="flex w-1/5 gap-2 mx-5 my-2 text-[18px] justify-center max-sm:w-[100%]">
            <input
                type="checkbox"
                id="isClient"
                name="isClient"
                value="isClient"
                onChange={() => setIsClient(!isClient)}
                style={{width:15, marginTop:5}}
            />
            <p className="text-[20px]">
                Es empresa?
            </p>
        </label>
        <div className="flex justify-center flex-wrap max-sm:w-[100%]">
            <label
                className="w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2 max-sm:w-[100%]" 
                htmlFor="nombre"
                >
                NOMBRE
                <input
                    className={`sm:w-full  text-[13px] border-1 text-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black`}
                    id="nombre"
                    type="text"
                    name="nombre"
                    value={user && user.nombre || ''}
                    onChange={(e)=> handleChange(e)}
                />
                {
                    error.nombre && <p className='text-error text-[11px]'>Debe ingresar solo letras.</p>
                }
            </label>
            <label
                className="max-sm:w-[100%] w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="apellido"
                >
                APELLIDO
                <input
                    className="max-sm:w-[100%] text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="apellido"
                    type="text"
                    name="apellido"
                    value={user && user.apellido || ''}
                    disabled={isClient === false}
                    onChange={(e)=> handleChange(e)}
                />
                {
                    error.apellido && <p className='text-error text-[11px]'>Debe ingresar solo letras.</p>
                }
            </label>


            <label className="w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="tipodocidentidadid"
            >
                TIPO DOCUMENTO
                <select className='text-gray-03 text-[13px] border-1 border-gray-03 p-2 pl-4 focus:outline-0 focus-within:text-black rounded-[5px]' id='tipodocidentidadid' name='tipodocidentidadid'
                value={user && user.tipodocidentidadid || ''}
                onChange={(e)=> handleChange(e)}>
                <option className='text-[16px]' value=''>SELECCIONE UNA OPCIÓN</option>
                {
                    documentos && documentos.map((p) =>
                    <option className='text-[16px]' key={p.tipodocidentidadid} value={p.tipodocidentidadid}>{p.nombre}</option>
                    )
                }
                </select>
            </label>

            <label
                className="w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="cuit"
                >
                    NUMERO DOCUMENTO
                <input
                    className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="cuit"
                    type="cuit"
                    name="cuit"
                    value={user && user.cuit || ''}
                    onChange={(e)=> handleChange(e)}
                />
                {
                    error.cuit && <p className='text-error text-[11px]'>Debe ingresar solo n&uacute;meros.</p>
                }
            </label> 

            <label
                className="max-sm:w-[100%] w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="email"
                >
                EMAIL
                <input
                    className="max-sm:w-[100%] text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="email"
                    type="email"
                    name="email"
                    value={user && user.email || ''}
                    onChange={(e)=> handleChange(e)}
                />
            </label>

            <label
                className="max-sm:w-[100%] w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="telefono"
                >
                    TELEFONO
                <input
                    className="max-sm:w-[100%] text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="telefono"
                    type="telefono"
                    name="telefono"
                    value={user && user.telefono || ''}
                    onChange={(e)=> handleChange(e)}
                />
                {
                    error.telefono && <p className='text-error text-[11px]'>Debe ingresar solo n&uacute;meros.</p>
                }
            </label>  
            <label
                className="max-sm:w-[100%] w-1/4 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="celular"
                >
                    CELULAR
                <input
                    className="max-sm:w-[100%] text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="celular"
                    type="celular"
                    name="celular"
                    value={user && user.celular || ''}
                    onChange={(e)=> handleChange(e)}
                />
                {
                    error.celular && <p className='text-error text-[11px]'>Debe ingresar solo n&uacute;meros.</p>
                }
            </label>
            <label className="max-sm:w-[100%] w-1/4 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="province"
            >
                CONDICION IVA
                <select className='max-sm:w-[100%] text-gray-03 text-[13px] border-1 border-gray-03 p-2 pl-4 focus:outline-0 focus-within:text-black rounded-[5px]' id='province' name='province'
                value={user && user.tipocondicionivaid || ''}
                onChange={(e)=> handleChange(e)}>
                <option className='text-[16px]' value=''>SELECCIONE UNA OPCIÓN</option>
                {
                    tipoIva && tipoIva.map((p) =>
                    <option className='text-[16px]' key={p.tipocondicionivaid} value={p.tipocondicionivaid}>{p.nombre}</option>
                    )
                }
                </select>
            </label>

            <label className="max-sm:w-[100%] w-1/4 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="province"
            >
                CONDICION PAGO
                <select className='text-gray-03 text-[13px] border-1 border-gray-03 p-2 pl-4 focus:outline-0 focus-within:text-black rounded-[5px]' id='province' name='province'
                value={user && user.tipocondicionpagoid || ''}
                onChange={(e)=> handleChange(e)}>
                <option className='text-[16px]' value=''>SELECCIONE UNA OPCIÓN</option>
                {
                    tipoPago && tipoPago.map((p) =>
                    <option className='text-[16px]' key={p.tipocondicionpagoid} value={p.tipocondicionpagoid}>{p.nombre}</option>
                    )
                }
                </select>
            </label>

            <label
                className="w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="celular"
                >
                    NUEVA CLAVE 
                <input
                    className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="celular"
                    type="password"
                    name="celular"
                    value={password}
                    onChange={(e) =>
                        setPassword(e.target.value)
                    }
                />
            </label>
            

            <label
                className="w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="confirmPassword"
                >
                    CONFIRMAR CLAVE
                <input
                    className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) =>
                        setConfirmPassword(e.target.value)
                    }
                />
            </label>

            <label className="max-sm:w-[100%] flex w-1/5 gap-2 mx-5 mt-10 text-[18px] items-center justify-center">
                <input
                    type="checkbox"
                    id="actualizarDireccion"
                    name="actualizarDireccion"
                    value={actualizarDireccion}
                    onChange={() => setActualizarDireccion(!actualizarDireccion)}
                    style={{width:15, marginTop:5}}
                />
                <p className="text-[20px]">
                    Actualizar direcci&oacute;n
                </p>
            </label>

           <div className="flex flex-wrap w-full mx-auto justify-center tems-center ">
           <label className="max-sm:w-[100%] w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="provinciaid"
            >
                PROVINCIA
                <select className='max-sm:w-[100%] text-gray-03 text-[13px] border-1 border-gray-03 p-2 pl-4 focus:outline-0 focus-within:text-black rounded-[5px]' id='provinciaid' name='provinciaid'
                value={user && user.provinciaid || ''}
                onChange={(e)=> handleChange(e)}>
                <option className='text-[16px]' value=''>SELECCIONE UNA OPCIÓN</option>
                {
                    provincias && provincias.map((p) =>
                    <option className='text-[16px]' key={p.provinciaid} value={p.provinciaid}>{p.nombre}</option>
                    )
                }
                </select>
            </label>
            <label className="max-sm:w-[100%] w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="ciudadid"
            >
                CIUDAD
                <select className='max-sm:w-[100%] text-gray-03 text-[13px] border-1 border-gray-03 p-2 pl-4 focus:outline-0 focus-within:text-black rounded-[5px]' 
                id='ciudadid' name='ciudadid'
                value={user && user.ciudadid || ''}
                onChange={(e)=> handleChange(e)}>
                <option className='text-[16px]' value=''>SELECCIONE UNA OPCIÓN</option>
                {
                    localidades && localidades.map((p) =>
                    <option className='text-[16px]' key={p.localidadid} value={p.localidadid}>{p.nombrelocalidad}</option>
                    )
                }
                </select>
            </label>
            <label
                className="max-sm:w-[100%] w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="direccion"
                >
                CALLE 
                <input
                    className="max-sm:w-[100%] text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="direccion"
                    type="direccion"
                    name="direccion"
                    value={user && user.direccion || ''}
                    onChange={(e)=> handleChange(e)}
                />
            </label>
            <label
                className="w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="altura"
                >
                ALTURA
                <input
                    className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="altura"
                    type="altura"
                    name="altura"
                    value={user && user.altura || ''}
                    onChange={(e)=> handleChange(e)}
                />
                {
                    error.altura && <p className='text-error text-[11px]'>Debe ingresar solo n&uacute;meros.</p>
                }
            </label>

            <label
                className="w-2/5 flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue mx-5 my-2"
                htmlFor="codigopostal"
                >
                CODIGO POSTAL
                <input
                    className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
                    id="codigopostal"
                    type="codigopostal"
                    name="codigopostal"
                    value={user && user.codigopostal || ''}
                    onChange={(e)=> handleChange(e)}
                />
            </label>
           </div>
 
            
            
        </div>  
        <button
            className="text-blue text-[16px] my-5 py-2 px-20 bg-black rounded-[5px] hover:text-white duration-200"
            onClick={() => handleEditProfile()}
            style={{color: colorFondoSubNav}}
          > 
            EDITAR PERFIL
          </button>    
      </div>
    </div>
  )
}