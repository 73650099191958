import { useSelector } from 'react-redux'

import { Link } from 'react-router-dom'

import {
  WhatsappLogo,
  InstagramLogo,
  FacebookLogo,
} from '@phosphor-icons/react'

import logo_default from '../media/logo_develshops.png'

import { urlCore } from '../config'
import { postEmailNewsletter } from '../methods'
import { useState } from 'react'
import { toast } from 'sonner'

export default function Footer() {
  let dataStore = useSelector((state) => state.dataStore)
  let dataStore2 = useSelector((state) => state.dataStore2)
  let configData = useSelector((state) => state.configData)

  let colores = JSON.parse(localStorage.getItem('dataStore')) || ''
  let colorFondoSubNav = dataStore?.ecommerce_colorFondoSubNav === "#454545" ? "#0661f4" : dataStore?.ecommerce_colorFondoSubNav
  let colorTextoSubNav = dataStore?.ecommerce_colorTextoSubNav

  const [email, setEmail] = useState('')

  async function sendEmail(){
    var validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/
    if( validEmail.test(email) ){
      let response = await postEmailNewsletter(email)
      setEmail('')
      toast(
        <div className="flex flex-col gap-2 text-[16px]">
          <p>{response.mensaje}</p>
        </div>
      )
    }else{
      toast(
        <div className="flex flex-col gap-2 text-[16px]">
          <p>Debe ingresar un email v&aacute;lido.</p>
        </div>
      )
    }
  }

  return (
    <div className="w-full" style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colores.ecommerce_colorTerciario,
    }}
    >
      <div className="w-[80vw] mx-auto">
        {dataStore?.default_whatsapp_api === '1' && (
          <Link
            style={{backgroundColor:colorFondoSubNav, color:colorTextoSubNav}}
            className="flex items-center fixed z-[1000] right-[20px] bottom-[20px] p-2 bg-black text-blue border-1 border-white rounded-[5px] hover:text-white duration-200"
            to={dataStore?.url_whatsapp_api}
            target="_blank"
          >
            <WhatsappLogo size={28} />
          </Link>
        )}
        <div 
          style={{borderColor:colorFondoSubNav}}
          className="flex flex-col gap-4 md:gap-0 md:flex-row justify-center md:justify-between items-center py-6 border-b-1 border-blue">
          {/* NEWSLETTER */}
          <div className='text-[13px]'>
              <input 
                className='p-2 pl-6 rounded-l-[15px] border-1 border-white focus:outline-0' 
                placeholder='mail@ejemplo.com' 
                id="emailNewsletter"
                name="email"
                value={email}
                onChange={(e) =>
                  setEmail(e.target.value)
                }
              />
              <button 
                style={{backgroundColor:colorFondoSubNav, color:colorTextoSubNav, border:"solid 1px"}}
                className='p-2 rounded-r-[15px] text-black font-bold hover:text-white hover:bg-black duration-200'
                onClick={()=>sendEmail()}
              >
                Newsletter
              </button>
          </div>
          {
            dataStore?.imagenlogo ?
            <img
              className="w-[54px] object-cover md:mr-[100px]"
              src={`${urlCore}/archivos/logos/cart/${configData?.tiendaid}/${dataStore?.imagenlogo}`}
              alt="Logo Develone"
            /> :
            <img
              className="w-[54px] object-cover md:mr-[100px]"
              src={logo_default}
              alt="Logo Develone"
            /> 
          }
          <ul className="flex gap-2">
            <li style={{backgroundColor:colorFondoSubNav, color:colorTextoSubNav, borderRadius:"100%"}}>
              <Link
                //style={{backgroundColor:colorFondoSubNav, color:colorTextoSubNav, border:"solid 1px", borderBlockColor:colorTextoSubNav}}
                className="w-[50px] h-[50px] rounded-full flex justify-center items-center group hover:bg-white hover:text-black duration-200"
                to={dataStore?.instagram_url}
                target="_blank"
              >
                <InstagramLogo
                  //style={{backgroundColor:colorFondoSubNav, color:colorTextoSubNav}}
                  className="text-white hover:text-black duration-200"
                  size={30}
                />
              </Link>
            </li>
            <li style={{backgroundColor:colorFondoSubNav, color:colorTextoSubNav, borderRadius:"100%"}}>
              <Link
                //style={{backgroundColor:colorFondoSubNav, color:colorTextoSubNav, border:"solid 1px", borderBlockColor:colorTextoSubNav}}
                className="w-[50px] h-[50px] rounded-full flex justify-center items-center group hover:bg-white hover:text-black duration-200"
                to={dataStore?.facebook_url}
                target="_blank"
              >
                <FacebookLogo
                  //style={{backgroundColor:colorFondoSubNav, color:colorTextoSubNav}}
                  className="text-white hover:text-black duration-200"
                  size={30}
                />
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-col justify-center items-center sm:items-start gap-4 md-gap-0 md:flex-row flex-wrap justify-between py-6">
          
          {dataStore2?.footer?.length > 0 &&
            dataStore2?.footer?.map((footerInfo, i) => (
              <div
                className="flex flex-col justify-center items-center w-[200px]"
                key={i}
              >
                <h3 className="text-white font-bold text-[16px]">
                  {footerInfo.titulo}
                </h3>
                <ul className="mt-2">
                  {footerInfo.hijos?.map((info, o) => (
                    <li
                      className="text-sm text-white text-center hover:text-blue"
                      key={o}
                    >
                      <Link
                        to={
                          info.subtitulo == 'Admin'
                            ? info.url
                            : `./footer/${info.subtitulo}`
                        }
                      >
                        {info.subtitulo}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          {/* <div className="flex flex-col items-center flex-1">
            <h3 className="text-white font-bold text-[16px]">NAVEGACIÓN</h3>
            <ul className="flex flex-col text-blue text-[13px] items-center mt-4">
              <li>Inicio</li>
              <li>Productos</li>
              <li>Mi cuenta</li>
              <li>Admin</li>
            </ul>
          </div>
          <div className="flex flex-col items-center border-y-1 md:border-y-0 md:border-x-1 border-blue flex-1 py-4 md:p-0">
            <h3 className="text-white font-bold text-[16px]">AYUDA</h3>
            <ul className="flex flex-col text-blue text-[13px] items-center mt-4">
              <li>Cómo comprar</li>
              <li>Entrega</li>
              <li>Cambios y devoluciones</li>
              <li>FAQ</li>
              <li>Medios de pago</li>
              <li>Formas de envío</li>
            </ul>
          </div>
          <div className="flex flex-col items-center flex-1">
            <h3 className="text-white font-bold text-[16px]">LIBREMÁS</h3>
            <ul className="flex flex-col text-blue text-[13px] items-center mt-4">
              <li>{dataStore?.mail_direccion}</li>
              <li>Sagrada familia 1076</li>
              {dataStore?.default_whatsapp_api === "0" && (
                <li>{dataStore?.whatsapp_numero}</li>
              )}
            </ul>
          </div> */}
        </div>
      </div>
      <a href='https://develone.com/' target='_blank'>
        <h5 className="text-white font-bold text-[14px]" style={{margin:20}}>
          Creado por Develshops
        </h5>
      </a>
    </div>
  )
}
