import React from "react"
import Modal from 'react-modal';
import image from './sitioenmantenimiento.png'

Modal.setAppElement("#root")

export const ModalMaintenance = ({show}) =>{
    
    const customStyles = {
        content: {
          padding: "40px",
          inset: "unset",
          width: "100%",
          maxHeight: "90vh",
          borderRadius: "8px",
          maxWidth: "650px",
        //   backgroundColor: "#D1D1D1"
        },
        overlay: {
          backgroundColor: "rgba(0,0,0,0.5)",
          display: "grid",
          placeItems: "center",
          zIndex: "100000",
        },
    };

    return (
        <Modal isOpen={show === "0" || show === undefined ? false : true} style={customStyles}>
            <h5 style={{fontSize:20}}>El sitio se encuentra en mantenimiento. Disculpe las molestias.</h5>
            <div style={{display:"flex", justifyContent:"center", margin:15}}>   
                <img src={image} style={{width:300}}/>
            </div>
        </Modal>
    )
}