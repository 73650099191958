import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const UseRemoveTildes = (text) => {
  // Mapa de caracteres con tildes y sus equivalentes sin tilde
  const mapaTildes = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    ñ: "n",
    Ñ: "N",
  };

  // Reemplazar los caracteres con tilde según el mapa
  const cadenaSinTildes = text.replace(/[áéíóúÁÉÍÓÚñÑ]/g, function (match) {
    return mapaTildes[match];
  });

  return cadenaSinTildes;
};
