import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getDataStore, recoverPassword } from './../methods/index'

import { Toaster, toast } from 'sonner'

import { ModalMaintenance } from './ModalMaintenance'

export default function RecoverPassword() {

  const [email, setEmail] = useState('')

  const [dataStore, setDataStore] = useState('')
  let enMantenimiento = dataStore?.default_mantenimiento  

  let configData = useSelector((state) => state.configData)

  useEffect(() => {
    ;(async () => {
      const data = await getDataStore(configData.tiendaid)
      setDataStore(data)
    })()
  }, [])

  async function handleRecoverPassword(email) {
    let message = await recoverPassword(email, configData.api_key)
    toast(
      <div className="flex flex-col gap-2 text-[13px]">
        <p>{message}</p>
      </div>
    )
  }

  return (
    <div className="w-[80vw] mx-auto mb-60">
      <ModalMaintenance
        show={enMantenimiento}
      />
      <Toaster />
      <p className="text-[13px] mt-12">
        INICIO /{' '}
        <span className="font-bold text-[16px]">RECUPERAR CONTRASEÑA</span>
      </p>
      <div className="flex flex-col gap-6 my-8">
        <div>
          <label
            className="w-full sm:w-[300px] flex flex-col gap-2 text-black text-[16px] focus-within:text-blue"
            htmlFor="email"
          >
            EMAIL DE LA CUENTA
            <input
              className="w-full text-black text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
              id="email"
              placeholder="ejemplo@ejemplo.com"
              place
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <button
            className="border-1 border-black text-xs bg-black text-blue px-2 py-1 rounded-[5px] my-2 hover:text-white duration-200"
            onClick={() => handleRecoverPassword(email)}
          >
            RECUPERAR CONTRASEÑA
          </button>
        </div>
        <div className="w-[100%] sm:w-[600px] bg-gray-01 p-2 rounded-[5px]">
          <p className="text-sm">
            En el caso de que no pueda solucionar el problema puede contartarse
            con nosotros a través de:{' '}
            <span className="text-sm font-bold">
              {dataStore?.mail_direccion}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}
