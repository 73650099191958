import { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import { getMensajes } from '../methods/index'

import { Ring } from '@uiball/loaders'

import { UseScrollToTop } from '../utilities/UseScrollToTop'

import { ModalMaintenance } from './ModalMaintenance'
import { ModalMessage } from './ModalMessage'

export default function Messages() {

  let dataStore = useSelector((state) => state.dataStore)
  let enMantenimiento = dataStore?.default_mantenimiento  
  
  const user = useSelector((state) => state.user)

  const [page, setPage] = useState(1)

  const [numOrders, setNumOrders] = useState(0)
  const [messages, setMessages] = useState('')

  const [message, setMessage] = useState('')

  const [show, setShow] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (row) => {
    setMessage(row)
    setShow(true)
  }
  useEffect(() => {
    ;(async () => {
      const info = await getMensajes(user,page)
      setMessages(info)
      setNumOrders(info.length)
    })()
  }, [])

  return (
    <div className="w-[80vw] mx-auto">
      <ModalMaintenance
        show={enMantenimiento}
      />
      <ModalMessage
        show={show}
        message={message}
        handleClose={handleClose}
      />
      <UseScrollToTop />
      <p className="text-[13px] flex items-center gap-2 my-12">
        <span className="font-bold text-[16px] text-white bg-black p-2 rounded-full w-[30px] h-[30px] flex justify-center items-center">
          {numOrders}
        </span>{' '}
        <span className="font-bold text-[16px]">MENSAJES</span>
      </p>
      <h2 className="text-[22px] border-b-1 border-black pb-2">Mis mensajes</h2>
      {messages.length > 0 ? (
        <div className="flex flex-wrap gap-4 w-full text-center rounded-[5px] mt-12 mb-28">
          {messages.map((order) => {
            return (
              <div
                className="flex flex-col w-[200px] overflow-hidden sm:w-[350px] border-1 border-gray-01 rounded-t-[10px] rounded-b-[5px] hover:cursor-pointer border-black group duration-200"
                key={order.id}
                onClick={()=>handleShow(order)}
              >
                <p className="pl-2 text-left italic text-white p-1 rounded-t-[5px] text-[14px] bg-black group-hover:text-blue">
                  {order.fecha+' '+order.hora}
                </p>
                <div className="flex flex-col text-center text-sm">
                  {/* <p className="p-2 border-b-1 border-gray-01 mx-2">
                    <span className="font-bold">Usuario:</span>{' '}
                    {order.nombrecliente.length > 20
                      ? order.nombrecliente.slice(0, 20) + '...'
                      : order.nombrecliente}
                  </p> */}
                  <p className="p-2 border-b-1 border-gray-01 mx-2">
                    <span className="font-bold">{order.titulo}</span>{' '}
                  </p>
                  <p className="p-2 border-gray-01 mx-2">
                  {order.mensaje.length > 100
                      ? order.mensaje.slice(0, 100) + '...'
                      : order.mensaje
                  }
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      ) : typeof messages === 'string' ? (
        <div className="h-[45vh] flex justify-center items-center">
          <Ring
            className="text-black"
            size={40}
            lineWeight={5}
            speed={2}
            color="black"
          />
        </div>
      ) : (
        <div className="h-[45vh] flex justify-center items-center">
          <p>No tiene mensajes</p>
        </div>
      )}
    </div>
  )
}
