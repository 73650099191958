export function setDataStore(data) {
  return { type: 'SET_DATA_STORE', payload: data }
}
export function setDataStore2(data) {
  return { type: 'SET_DATA_STORE_2', payload: data }
}
export function setConfig(data) {
  return { type: 'SET_CONFIG', payload: data }
}

export function logIn(user) {
  return { type: 'LOG_IN', payload: user }
}

export function setClientSession(data) {
  return { type: 'CLIENT_SESSION', payload: data }
}

export function logOut() {
  return { type: 'LOG_OUT' }
}

export function setProductsCart(data) {
  return { type: 'SET_PRODUCTS_CART', payload: data }
}

export function setInvoiceId(data) {
  return { type: 'SET_INVOICE_ID', payload: data }
}
export function setTotalProducts(data) {
  return { type: 'SET_TOTAL_PRODUCTS', payload: data }
}
export function setCalculateInvoice(data) {
  return { type: 'SET_CALCULATE_INVOICE', payload: data }
}

export function deleteProductCar(id) {
  return { type: 'DELETE_PRODUCT', payload: id }
}

export function deleteCarProducts() {
  return { type: 'DELETE_CAR_PRODUCTS' }
}

export function setOrders(orders) {
  return { type: 'SET_ORDERS', payload: orders }
}

export function setCatalogProducts(products) {
  return { type: 'SET_CATALOG_PRODUCTS', payload: products }
}

export function setPaymentDataMP(data) {
  return { type: 'SET_MP_DATA', payload: data }
}
