import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useNavigate, Link } from 'react-router-dom'

import {
  setProductsCart,
  setCalculateInvoice,
  setTotalProducts,
} from '../actions/index'
import {
  getCartDetail,
  calculateInvoice,
  deleteProductCartMethod,
  deleteProducts,
  getTotalProducts,
  invoiceClient,
} from '../methods/index'

import { Ring } from '@uiball/loaders'

import { Toaster, toast } from 'sonner'

import ModalImage from 'react-modal-image'

import { UseScrollToTop } from './../utilities/UseScrollToTop'

import { ModalMaintenance } from './ModalMaintenance'

export default function Cart() {

  let dataStore = useSelector((state) => state.dataStore)
  let enMantenimiento = dataStore?.default_mantenimiento  

  let dispatch = useDispatch()

  let cartProducts = useSelector((state) => state.cartProducts)
  let clientSession = useSelector((state) => state.clientSession)
  let user = useSelector((state) => state.user)
  let invoiceId = useSelector((state) => state.invoiceId)
  let totalProducts = useSelector((state) => state.totalProducts)
  let invoiceData = useSelector((state) => state.invoiceData)
  let unknown = useSelector((state) => state.unknown)
  let iamclient = useSelector((state) => state.iamclient)
  let colorFondoSubNav = dataStore?.ecommerce_colorFondoSubNav === "#454545" ? "#0661f4" : dataStore?.ecommerce_colorFondoSubNav

  let navigate = useNavigate()

  const [cartProductsState, setCarProductsState] = useState(null)
  useEffect(() => {
    ;(async () => {
      let clientId = user ? user.id : clientSession
      let data = await getCartDetail(clientId)
      if(data.facturaDetalleEliminar !== 0 && data.facturaDetalleEliminar !== undefined){
        let datos = await deleteProductCartMethod(clientId,data.facturaDetalleEliminar,invoiceId)
        if(datos.success){
          data = await getCartDetail(clientId)
          dispatch(setTotalProducts(await getTotalProducts(clientId)))
          dispatch(setProductsCart(data.products))
          setCarProductsState(data)
          let newInvoiceId = await calculateInvoice(clientId,invoiceId)
          dispatch(setCalculateInvoice(newInvoiceId))
          await invoiceClient(clientId, newInvoiceId.facturaid)
        }
      }else{
        let data = await getCartDetail(clientId)
        dispatch(setTotalProducts(await getTotalProducts(clientId)))
        dispatch(setProductsCart(data.products))
        setCarProductsState(data)
        let newInvoiceId = await calculateInvoice(clientId,invoiceId)
        dispatch(setCalculateInvoice(newInvoiceId))
        await invoiceClient(clientId, newInvoiceId.facturaid)
      }
    })()
    return () => {
      dispatch(setProductsCart(null))
    }
  }, [])
  async function handleDeleteProduct(productId) {
    let clientId = user ? user.id : clientSession
    let productDeleted = await deleteProductCartMethod(clientId, productId, invoiceId)
    if (productDeleted.success) {
      let data = await getCartDetail(clientId)
      setCarProductsState(data)
      dispatch(setProductsCart(data.products))
      dispatch(setTotalProducts(await getTotalProducts(clientId)))
      dispatch(setCalculateInvoice(await calculateInvoice(clientId,invoiceId)))
      toast(
        <div className="flex flex-col gap-2 text-[13px]">
          <p>{productDeleted.mensaje}</p>
        </div>
      )
    }
  }
  async function handleDeleteProducts() {
    let clientId = user ? user.id : clientSession
    let cartDeleted = await deleteProducts(clientId, invoiceId)
    if (cartDeleted.success) {
      let data = await getCartDetail(clientId)
      dispatch(setProductsCart(data.products))
      dispatch(setTotalProducts(await getTotalProducts(clientId)))
      dispatch(setCalculateInvoice(await calculateInvoice(clientId,invoiceId)))
      toast(
        <div className="flex flex-col gap-2 text-[13px]">
          <p>Productos eliminados del carrito</p>
        </div>
      )
    }
  }
  async function handleCheckout() {
    localStorage.setItem('listadoproductos', JSON.stringify(cartProductsState.products))
    if (unknown == 1) {
      navigate('/registrarse')
    } else if (iamclient == 1) {
      navigate('/checkout')
    }
  }

  return (
    <div className="w-[80vw] mx-auto">
      <ModalMaintenance
        show={enMantenimiento}
      />
      <UseScrollToTop />
      <Toaster />
      <p className="text-[13px] mt-12 flex flex-wrap sm:flex-nowrap items-center gap-2">
        CARRITO /{' '}
        <span className="font-bold text-[16px] text-white bg-black p-2 rounded-full w-[30px] h-[30px] flex justify-center items-center">
          {totalProducts}
        </span>{' '}
        <span className="font-bold text-[14px] sm:text-[16px]">
          {' '}
          PRODUCTOS POR COMPRAR
        </span>
      </p>
      {cartProducts?.length > 0 ? (
        <>
          {cartProducts?.map((p, i) => (
            <div
              className="flex flex-col sm:flex-row items-start justify-between my-4 border-y-1 border-gray-02"
              key={i}
            >
              <ModalImage
                small={p.image}
                large={p.image}
                className="shrink-0 h-[200px] w-[250px] object-cover"
                hideDownload={true}
                hideZoom={true}
              />
              <div className="flex flex-col w-full p-0 sm:py-4 sm:px-8 gap-2">
                {
                  p.name !== "" && 
                  <Link to={`/producto/${p.name?.replace(/-/g, ' ')}/${p.id}`}>
                    <h2 className="font-bold text-[20px]">{p.name} {p.medida}</h2>
                  </Link>
                }
                <p>${p.price} POR UNIDAD</p>
                <div className="flex gap-2">
                  <input
                    className="text-[16px] border-1 border-gray-02 rounded-[5px] p-1 text-center focus:outline-0 focus:border-blue"
                    type="number"
                    readOnly
                    value={p.quantity}
                  />
                </div>
                {/* <p className='text-[13px] text-gray-02'>{p.quantity} disponibles</p> */}  
                <p className="font-bold text-[16px] my-4 sm:m-0">
                  TOTAL $
                  {(
                    parseFloat(p.price.replace(/\./g, '').replace(',', '.')) *
                    p.quantity
                  ).toLocaleString('es-ES', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
              <button
                style={{color:colorFondoSubNav}}
                className="text-blue text-[13px] bg-black p-2 hover:text-white duration-200"
                onClick={() => handleDeleteProduct(p.invoiceId)}
              >
                Eliminar
              </button>
            </div>
          ))}
          {/* <div className="flex flex-col lg:flex-row items-center justify-center my-14 gap-8"> */}
          {/* CALCULATE SHIPPING */}
          {/* <div className="flex flex-col gap-8 items-center w-full lg:w-[40%]">
              <div className="flex items-center gap-8">
                <Package
                  className="p-2 bg-black text-white rounded-full"
                  size={62}
                />
                <div className="flex flex-col gap-2">
                  <h3 className="text-[16px] font-bold">
                    CALCULAR EL COSTO DE ENVÍO
                  </h3>
                  <div className="flex">
                    <input
                      className="text-[13px] text-center border-1 border-black p-1 rounded-l-[5px] focus:outline-0 focus:border-blue"
                      type="number"
                      placeholder="Código postal"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                    />
                    <button
                      className="text-[13px] text-blue bg-black p-1 border-1 border-black rounded-r-[5px] hover:text-white"
                      onClick={() => handleCalculateShipping()}
                    >
                      CALCULAR
                    </button>
                  </div>
                </div> */}
          {/* </div> */}
          {/* <p className="text-center px-10 text-[14px] font-bold">
                NUESTROS ENVÍOS SON DESPACHADOS UNA VEZ QUE TE HA LLEGADO EL
                MAIL DE CONFIRMACIÓN DE COMPRA. LUEGO DE ESTO LOS TIEMPOS DE
                ENTREGA SON DE 2 DÍAS HÁBILES.
              </p>
            </div> */}
          {/* </div> */}
          {/* RESUME */}
          <div className="w-[100%] sm:w-[60vw] mx-auto flex flex-col gap-8 border-1 border-gray-02 rounded-[10px] p-4 my-10 font-bold text-[16px]">
            <div className="flex justify-between border-b-1 border-gray-02">
              <p>SUBTOTAL</p>
              <p>
                {invoiceData.simbolo}
                {invoiceData.subtotal}
              </p>
            </div>
            <div className="flex justify-between border-b-1 border-gray-02">
              <p>ENVÍO</p>
              <p>
                {invoiceData.simbolo}
                {invoiceData.descuento}
              </p>
            </div>
            <div className="flex justify-between border-b-1 border-gray-02">
              <p>TOTAL</p>
              <p>
                {invoiceData.simbolo}
                {invoiceData.total}
              </p>
            </div>
          </div>
          {/* BUTTONS */}
          <ul className="flex flex-wrap justify-between gap-4 w-[100%] sm:w-[60vw] mx-auto items-center mb-12">
            <li className="flex-grow">
              <button
                style={{color:colorFondoSubNav}}
                className="w-full p-2 bg-black text-blue text-[16px] rounded-[5px] hover:text-white duration-200"
                onClick={() => navigate('/')}
              >
                SEGUIR COMPRANDO
              </button>
            </li>
            <li className="flex-grow">
              <button
                style={{color:colorFondoSubNav}}
                className="w-full p-2 bg-black text-blue text-[16px] rounded-[5px] hover:text-white duration-200"
                onClick={() => handleDeleteProducts()}
              >
                VACIAR CARRITO
              </button>
            </li>
            <li className="flex-grow">
              <button
                style={{color:colorFondoSubNav}}
                className="w-full p-2 bg-black text-blue text-[16px] rounded-[5px] hover:text-white duration-200"
                onClick={() => handleCheckout()}
              >
                CONFIRMAR
              </button>
            </li>
          </ul>
        </>
      ) : !cartProductsState ? (
        <div className="h-[45vh] flex justify-center items-center">
          <Ring
            className="text-black"
            size={40}
            lineWeight={5}
            speed={2}
            color="black"
          />
        </div>
      ) : (
        <div className="flex flex-col items-center border-1 border-gray-02 rounded-[5px] px-2 py-6 my-44 w-[8-vw] md:w-[400px] mx-auto">
          <p className="text-center text-black">
            No agregaste ningún producto al carrito
          </p>
          <button
            className="mt-4 px-4 py-1 bg-black text-blue text-[13px] rounded-[5px] hover:text-white duration-200"
            onClick={() => navigate('/')}
            style={{color: colorFondoSubNav}}
          >
            VER PRODUCTOS
          </button>
        </div>
      )}
    </div>
  )
}
