import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ModalMaintenance } from './ModalMaintenance'

export default function FooterInfo() {
  let dataStore2 = useSelector((state) => state.dataStore2);
  let dataStore = useSelector((state) => state.dataStore)
  let enMantenimiento = dataStore?.default_mantenimiento  

  const { title } = useParams();

  function decodeEntities(encodedString) {
    var textArea = document.createElement("textarea");
    textArea.innerHTML = encodedString;
    return textArea.value;
  }

  return (
    <div className="w-[80vw] mx-auto my-12">
      <ModalMaintenance
        show={enMantenimiento}
      />
      <h2 className="text-[13px]">
        <span className="font-bold text-[16px]">{title.toUpperCase()}</span>
      </h2>
      <div>
        {dataStore2?.footer
          .map((info) => info.hijos)
          .map((children) =>
            children
              .filter(
                (infoFooter) =>
                  infoFooter.subtitulo === title && infoFooter.descripcion
              )
              .map((filteredChild, index) => (
                <div key={index}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: decodeEntities(filteredChild.descripcion),
                    }}
                  />
                </div>
              ))
          )}
      </div>
    </div>
  );
}
