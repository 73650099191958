import React, { useState } from "react"
import Modal from 'react-modal';
import {
    APIProvider, 
    AdvancedMarker, 
    InfoWindow, 
    Map, 
    Pin, 
    useAdvancedMarkerRef
} from '@vis.gl/react-google-maps';
import { google_map_apikey, map_id } from "../config";

Modal.setAppElement("#root")

export const ModalMapPoints = ({show, handleClose, points, point, setPoint, setPuntoId, cotizarDeNuevo}) =>{
    
    const customStyles = {
        content: {
          padding: "40px",
          inset: "unset",
          width: "90%",
          height: "90vh",
          borderRadius: "8px",
          display:"flex",
        //   backgroundColor: "#D1D1D1"
        },
        overlay: {
          backgroundColor: "rgba(0,0,0,0.5)",
          display: "grid",
          placeItems: "center",
          zIndex: "100000",
        },
    };

    const position = {lat: point.lat, lng: point.lng};
    const [markerRef, marker] = useAdvancedMarkerRef();

    return (
        <Modal isOpen={show} style={customStyles} onRequestClose={handleClose}>
            <div className="w-7/12 pr-10 overflow-auto scrollbar">
                <ul>
                    {
                        points.length > 0 && points.map((p) =>
                            <li className={`${p.id === point.id && 'bg-black text-white'} h-38 border-b-1 border-b-black px-1 py-1 hover:cursor-pointer`} 
                                onClick={()=>setPoint(p)}>
                                <h4 className="text-blue text-[16px] font-bold">{p.name.toUpperCase()}</h4>
                                <h5 className="text-[14px] font-bold">{p.address}</h5>
                                <ul className="mt-2 mb-2">
                                {
                                    p.schedule.map((s) =>
                                        <li>
                                            <h5 className="flex justify-between">{s.dia}{
                                                s.horarios.length === 2 ?
                                                <h5>{s.horarios[0].open.slice(0,5)} a {s.horarios[0].close.slice(0,5)} - {s.horarios[1].open.slice(0,5)} a {s.horarios[1].close.slice(0,5)}               
                                                </h5> :
                                                s.horarios.map((h) => <h5>{h.open.slice(0,5)} a {h.close.slice(0,5)}&nbsp;</h5>)
                                            }</h5>
                                        </li>
                                    )
                                }
                                </ul>
                            </li>
                        )
                    }
                </ul>
            </div>
            <APIProvider apiKey={google_map_apikey}>
            <Map center={position} zoom={12} mapId={map_id}>
                {
                    points.length > 0 && points.map ((p) => 
                    <AdvancedMarker 
                        position={{lat: p.lat, lng: p.lng}} 
                        onClick={()=>setPoint(p)}
                        key={p.id}
                    >
                        <Pin background={'#FFF56D'} glyphColor={'#F55353'} borderColor={'#F55353'} />
                    </AdvancedMarker>
                    )
                }
                {
                    point !== undefined && point !== '' ?
                    <AdvancedMarker ref={markerRef} position={{lat: point.lat, lng: point.lng}}>
                        <Pin 
                            background={'#AA2EE6'} 
                            glyphColor={'#72147E'} 
                            borderColor={'#72147E'} 
                            scale={1.5}
                        />
                        <InfoWindow anchor={marker}>
                            <div className="flex flex-col item-center justify-center">
                                <p className="p-1 font-bold">
                                {point.name}
                                </p>
                                <button 
                                    className="h-8 px-4 py-1 my-2 bg-blue text-white rounded-[5px] hover:text-black duration-200"
                                    onClick={()=>{
                                        setPuntoId(point.id)
                                        cotizarDeNuevo(parseInt(point.id))
                                        handleClose()
                                    }}
                                    >
                                        Seleccionar
                                </button>
                            </div>
                        </InfoWindow>
                    </AdvancedMarker> : null
                }
            </Map>
            </APIProvider>
        </Modal>
    )
}