import { useEffect, useState } from 'react'

import { getProductsByCategoryOrBrand, getSubcategorias } from '../methods/index'

import { useParams, Link } from 'react-router-dom'

import { Ring } from '@uiball/loaders'

import ProductCard from './ProductCard'

import { UseRemoveTildes } from '../utilities/UseRemoveTildes'
import { UseScrollToTop } from './../utilities/UseScrollToTop'

import { CaretLeft } from '@phosphor-icons/react'
import Pagination from './Pagination'
import { useSelector } from 'react-redux'

export default function ProductDetail() {
  const [products, setProducts] = useState(null)

  const [subCategories, setSubCategories] = useState([])

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState()

  const { id, categoryOrBrand, name, subCategory, idSubCategory, actualPage } = useParams()

  localStorage.setItem('categoryOrBrand',categoryOrBrand)

  let dataStore = useSelector((state) => state.dataStore)
  let colorFondoSubNav = dataStore?.ecommerce_colorFondoSubNav === "#454545" ? "#0661f4" : dataStore?.ecommerce_colorFondoSubNav

  useEffect(() => {
    ;(async () => {
      const isCategory = categoryOrBrand === 'categorias'
      let { data, lastPage } = await getProductsByCategoryOrBrand(id, idSubCategory, page, 10, isCategory)
      if( categoryOrBrand === 'categorias'){
        let subcategorias = await getSubcategorias(id)
        setSubCategories(subcategorias)
      }
      setLastPage(lastPage)
      setProducts(data)
    })()
  }, [page, idSubCategory])

  return (
    <div className="sm:w-[80vw] mx-auto">
      <UseScrollToTop />
      <div className="w-[80vw] mx-auto">
        <p className="text-[13px] my-12 flex items-center gap-2">
          {categoryOrBrand.toUpperCase()} /
          <span className="font-bold text-[16px]">{name.toUpperCase()}</span>
        </p>
        {products?.length > 0 && (
          <Link
            style={{color: colorFondoSubNav}}
            to={`/${UseRemoveTildes(categoryOrBrand)}/${actualPage}`}
            className="flex items-center w-max text-sm text-center mb-2 text-blue bg-black py-1 px-4 rounded-[5px] hover:text-white duration-200"
          >
            <CaretLeft size={18} />
            VER MÁS
          </Link>
        )}
      </div>
      <div className='flex justify-center'>

      {
          subCategories.length > 0 ?
          <div className="w-[300px] my-14">
            <p className="text-[18px] font-bold">Filtros</p>
            <div className='flex flex-col'>
            {
              subCategories.length > 0 && subCategories.map((sub) =>
                <Link
                to={`/productos/${UseRemoveTildes(categoryOrBrand)}/${name
                  .toLowerCase()}/${UseRemoveTildes(sub.nombre.replace(/\s+/g, '-').replace(/\//g, '-'))}/${id}/${sub.subcategoriaid}/1`}
              >
                {sub.nombre}
              </Link>
              )
            }
            </div> 
          </div> : null
        }
      <div className="flex flex-wrap justify-center my-8 gap-2">
        {products?.length > 0 ? (
          <div className="flex flex-col my-8 gap-2">
            <div className="flex sm:gap-[5px] lg:gap-[10px] justify-start flex-wrap w-[100%]">
              {products?.map((p) => (
                <ProductCard key={p.id} product={p} />
              ))}
            </div>
            <Pagination
            url={`productos/${UseRemoveTildes(categoryOrBrand)}/${name
              .toLowerCase()}/${id}`}
            actualPage={page}
            setPage={setPage}
            lastPage={lastPage}
            />
          </div>
        ) : !products ? (
          <div className="h-[60vh] flex mx-auto justify-center items-center">
            <Ring
              className="text-black"
              size={40}
              lineWeight={5}
              speed={2}
              color="black"
            />
          </div>
        ) : (
          <div className="h-[60vh] mx-auto flex justify-center items-center flex-col gap-4">
            <p className="text-center">
              No hay ningún producto en esta categoría
            </p>
            <Link
              style={{color: colorFondoSubNav}}
              to={`/${UseRemoveTildes(categoryOrBrand)}/${actualPage}`}
              className="text-blue bg-black py-1 px-4 rounded-[5px] hover:text-white duration-200"
            >
              VER MÁS {categoryOrBrand.toUpperCase()}
            </Link>
          </div>
        )}
      </div>


      </div>
    </div>
  )
}
