import React, { useEffect, useState } from "react"
import Modal from 'react-modal';
import image from './sitioenmantenimiento.png'

Modal.setAppElement("#root")

export const ModalMessage = ({
    show,
    messages,
    handleClose,
    pedidoid,
    clienteid,
    sendMessage,
    input,
    setInput,
    handleChange
}) =>{
    
    const customStyles = {
        content: {
            padding: "40px",
            inset: "unset",
            width: "100%",
            maxHeight: "95vh",
            overflow:"hidden",
            borderRadius: "8px",
            maxWidth: "650px",
          //   backgroundColor: "#D1D1D1"
          },
        overlay: {
          backgroundColor: "rgba(0,0,0,0.5)",
          display: "grid",
          placeItems: "center",
          zIndex: "100000",
        },
    };

    const [sended, setSended] = useState('')
    async function handleOnClick(){
        let r = await sendMessage(input)
        setSended(r)
    }

    useEffect(() =>{
        setInput({
            titulo: 'Pedido',
            mensaje: '',
            pedidoid: pedidoid
        })
    },[messages, sended])

    return (
        <Modal isOpen={show} style={customStyles} onRequestClose={handleClose}>
            <div className="flex justify-between border-b border-gray">
                <p className="text-[20px] m-2">Mensajes - Pedido #{pedidoid}</p>
                <p className="text-[25px] cursor-pointer" onClick={handleClose}>x</p>
            </div>
            <div className="flex flex-col border-b m-2 h-80 overflow-scroll overflow-x-hidden">
                {
                    messages.length > 0 && messages.map((message) => 
                        <div className={`m-2 w-2/4 ${message.usuario === clienteid ? 
                            "text-right self-end" : "text-left self-start"
                        }`}>
                            <p className="text-[12px]">{message.fecha}</p>
                            <div class={`bg-gray-01 p-4`}  role="alert">
                                <p class="text-sm">{message.mensaje}</p>
                                <p className={`${message.usuario === clienteid ? 
                                    "text-left" : "text-right" 
                                } text-[10px]`}>{message.hora}</p>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="flex m-2">
                <textarea
                    name="mensaje"
                    className="mr-2 w-4/5 text-[14px] border-1 border-gray-02 rounded-[5px] p-1 focus:outline-0 focus:border-blue"
                    value={input.mensaje}
                    onChange={handleChange}
                    rows={4}
                />
                <button
                    className="h-10 px-4 py-2 bg-blue text-white"
                    onClick={() => handleOnClick()}
                >Enviar</button>
            </div>
        </Modal>
    )
}