import { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import { getStores } from '../methods/index'

import { Ring } from '@uiball/loaders'

export default function Stores() {

    const [stores, setStores] = useState([])


    useEffect(() => {
        (async () => {
            setStores(await getStores())
        })()
        return () => {
            setStores([])
        }
    }, [])

    return (
        <div className='w-[80vw] mx-auto'>
                <p className='text-[13px] mt-12 flex items-center gap-2'>INICIO / <span className='font-bold text-[16px]'> TIENDAS</span></p>
            {
                stores?.length > 0 ?
                        <div className='flex flex-wrap gap-4 mt-8 mb-20 justify-center sm:justify-start'>
                            {stores.map(store =>
                                <Link className='h-max flex flex-col text-sm w-[140px] sm:w-[180px] border-1 border-gray-01 rounded-[5px] hover:bg-black hover:text-blue group duration-200' key={store.tiendaid}>
                                    <p className='pl-2 italic text-white p-1 rounded-t-[5px] text-xs bg-black group-hover:text-blue'>#{store.tiendaid}</p>
                                    <h3 className='text-center p-2 text-xl font-bold text-blue'>{store.nombretienda.length > 9 ? store.nombretienda.slice(0, 9) + '...' : store.nombretienda}</h3>
                                </Link>
                            )}
                        </div>
                :
                    <div className="h-[50vh] flex justify-center items-center">
                        <Ring className='text-black' size={40} lineWeight={5} speed={2} color="black" />
                    </div>
            }
        </div>
    )
}
