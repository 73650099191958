import { useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { UserCircle } from '@phosphor-icons/react'
import { Toaster, toast } from 'sonner'

import { logIn, invoiceClient } from '../methods'
import { logIn as logInAction } from './../actions/index'

import { UseScrollToTop } from './../utilities/UseScrollToTop'

import { ModalMaintenance } from './ModalMaintenance'

export default function LogIn() {
  let dispatch = useDispatch()
  let navigate = useNavigate()

  let dataStore = useSelector((state) => state.dataStore)
  let enMantenimiento = dataStore?.default_mantenimiento  
  let colorFondoSubNav = dataStore?.ecommerce_colorFondoSubNav === "#454545" ? "#0661f4" : dataStore?.ecommerce_colorFondoSubNav

  let userLog = useSelector((state) => state.user)
  let sessionHash = useSelector((state) => state.sessionHash)
  let configData = useSelector((state) => state.configData)
  let unknown = useSelector((state) => state.unknown)

  const [user, setUser] = useState({
    email: '',
    password: '',
  })

  async function handleLogIn() {
    let data = await logIn(user, sessionHash, unknown)
    let productoIdReturn = localStorage.getItem('productoIdReturn')
    let productoNameReturn = localStorage.getItem('productoNameReturn')

    if (data?.name) {
      if(productoIdReturn !== '0' && productoNameReturn !== 'null'){
        dispatch(logInAction(data))
        navigate(`/producto/${productoNameReturn}/${productoIdReturn}`)
        localStorage.setItem('productoIdReturn','0')
        localStorage.setItem('productoNameReturn','null')
      }else{
        dispatch(logInAction(data))
        navigate('/')
      }

    } else {
      toast(
        <div className="flex flex-col gap-2 text-[13px]">
          <p>{data?.error}</p>
        </div>
      )
    }
  }

  return (
    <div className="w-[80vw] mx-auto mt-12">
          <ModalMaintenance
        show={enMantenimiento}
      />
      <UseScrollToTop />
      <Toaster />
      <p className="text-[13px]">
        INICIO / <span className="font-bold text-[16px]">INGRESAR</span>
      </p>
      <div className="sm:w-[40vw] mx-auto m-20 flex justify-center flex-col items-center gap-4">
        <UserCircle
          className="bg-black p-2 rounded-full text-white"
          size={60}
        />
        <label
          className="w-full flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue"
          htmlFor="email"
        >
          EMAIL
          <input
            className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
            id="email"
            placeholder="ejemplo@ejemplo.com"
            place
            type="email"
            name="email"
            value={user.email}
            onChange={(e) =>
              setUser({
                ...user,
                [e.target.name]: e.target.value,
              })
            }
          />
        </label>
        <label
          className="w-full flex flex-col gap-2 text-gray-03 text-[16px] focus-within:text-blue"
          htmlFor="password"
        >
          CONTRASEÑA
          <input
            className="text-gray-03 text-[13px] border-1 border-gray-03 rounded-[5px] p-2 pl-4 focus:outline-0 focus-within:text-black"
            id="password"
            type="password"
            name="password"
            value={user.password}
            onChange={(e) =>
              setUser({
                ...user,
                [e.target.name]: e.target.value,
              })
            }
          />
        </label>
        <Link
          style={{color:colorFondoSubNav}}
          className="text-blue px-2 py-1 text-sm self-start bg-black rounded-[5px] hover:text-white duration-200"
          to="/recuperar-contraseña"
        >
          RECUPERAR CONTRASEÑA
        </Link>
        <div className="flex flex-col items-center gap-4 mt-4">
          <button
            style={{color:colorFondoSubNav}}
            className="text-blue text-[16px] py-2 px-20 bg-black rounded-[5px] font-light hover:text-white duration-200"
            onClick={() => handleLogIn()}
          >
            INGRESAR
          </button>
          <p className="text-[13px]">
            Si no tenés una cuenta{' '}
            <Link
              style={{color:colorFondoSubNav}}
              className="font-light p-2 bg-black text-blue rounded-[5px] hover:text-white duration-200"
              to="/registrarse"
            >
              registrate acá
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}
