function replaceNumberForName(dia){
    switch (dia) {
        case 1:
            return "Lunes"
        case 2:
            return "Martes"
        case 3:
            return "Miercoles"
        case 4:
            return "Jueves"
        case 5:
            return "Viernes"
        case 6:
            return "Sabado"
        case 7:
            return "Domingo"
    }
}

function newSchedule (schedule) {

    let array = []

    for(let j = 0; j < 7; j++){
        array[j] = {
            dia: j+1,
            horarios: []
        }
    }

    for(let i = 0; i < schedule.length; i++){
        for(let k=0; k < array.length; k++){
            if(array[k].dia === schedule[i].day){
                array[k].horarios.push({
                    open: schedule[i].open,
                    close: schedule[i].close
                })
            }
        }
    }

    for(let h = 0; h < array.length; h++){
        array[h].dia = replaceNumberForName(array[h].dia)
    }

    let limpio = array.filter(a => a.horarios.length > 0)

    return limpio
}

function matchTallaProducto(productos){

    let colores = productos.filter((f) => f.cantidadactiva !== 0 ).map((p) => p.nombrecolor).filter((f) => f !== '')
    let setColores = new Set(colores)
    let arrayColores = Array.from(setColores)

    let resultadoFinal = {};

    for(let a = 0; a < arrayColores.length; a++){
        resultadoFinal[arrayColores[a]] = []
        arrayColores[a] = {color: arrayColores[a], productoid: ''}
    }
    
    for(let i = 0; i < productos.length; i++){
        for(let j = 0; j < arrayColores.length; j++){
            if(productos[i].nombrecolor === arrayColores[j].color){
                if(arrayColores[j].productoid === ''){
                    arrayColores[j].productoid = productos[i].productoid
                }
                if(productos[i].cantidadactiva !== 0 && productos[i].nombremedida !== ""){
                    resultadoFinal[arrayColores[j].color].push({
                        medida: productos[i].nombremedida,
                        sku: productos[i].codigoarticulo,
                        cantidad:  productos[i].cantidadactiva
                    })
                }
            }
        }
    }

    return {tallas: resultadoFinal, colores: arrayColores}
}

module.exports = {
    newSchedule,
    matchTallaProducto
}